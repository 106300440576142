.LocationOn {
  height: 40px;
  width: 40px;
  display: flex;
  margin-bottom: 40px;
  cursor: pointer;
  transition: 0.3s ease;
  transform-origin: bottom center;
}

.LocationOn:hover {
  transform: scale(1.1);
}

.Default-layer-2x {
  height: 40px;
  width: 40px;
  display: flex;
  margin-bottom: 30px;
}

.Default-layer-3x {
  height: 30px;
  width: 30px;
  display: flex;
  margin-bottom: 40px;
}

.Marker-layer-group {
  height: 30px;
  width: 30px;
  display: flex;
}

.Marker-layer-group-2x {
  height: auto;
  width: auto;
  display: flex;
  margin-bottom: 28px;
  margin-right: 10px;
}

.marker-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
  transform: translateY(-18px) scale(var(--zoom-scale, 1));
  transform-origin: center;
}