.submit-button {
  display: flex;
  align-items: center;
  background: #ebf2f3;
  color: #307e82;
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
  border-radius: 6px;
  font-size: 13.5px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  width: 100%;
  white-space: nowrap;
  margin-top: 6px;
  transition: all 0.3s;
}

.submit-button:hover {
  background: rgb(0, 185, 191) !important;
  color: white !important;
}