.LegCollection {
  background: #f8f8f8;
  width: auto;
  display: block;
  margin: 0px;
  position: relative;
  height: auto;
}

.active {
  display: block;
}

.inactive {
  display: none !important;
}

.leg-list {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  max-width: 441px;
}

.leg {
  padding: 1.7em 1.2em;
  min-height: 72px;
  border-bottom: 1px solid #ebebeb;
}

// .leg:last-child {
//   border-bottom: none;
// }
.leg:hover {
  cursor: pointer !important;
}

.leg-sub {
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

.leg-detail-outer-container {
  font-weight: inherit;
  font-family: inherit;
  font-size: 100%;
  display: inline-block;
  text-align: right;
  margin-left: 0.5rem;
  padding-bottom: 1rem;
  padding-top: 0.75rem;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  align-self: center;
  display: flex;
}

.leg-detail-inner-container {
  line-height: 22px !important;
  max-height: 44px !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  font-size: 19px !important;
  overflow: hidden !important;
}

.leg-detail {
  overflow-wrap: break-word !important;

  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 1.33333em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
}

.leg-title-outer-container {
  font-weight: inherit;
  font-family: inherit;
  font-size: 100%;
  display: inline-block;
}

.leg-title-inner-container {
  line-height: 22px !important;
  max-height: 44px !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  font-size: 19px !important;
  overflow: hidden !important;
}

.leg-title {
  overflow-wrap: break-word !important;

  font-size: 16px !important;
  font-weight: 800 !important;
  line-height: 1.375em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
}

.leg-subtitle-container {
  line-height: normal !important;
  max-height: 22px !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  font-weight: inherit;
  font-family: inherit;
  font-size: 100%;
}

.leg-subtitle {
  overflow-wrap: break-word !important;

  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 1.33333em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
}

@media only screen and (max-width: 600px) {
  .LegCollection {
    padding-left: 0px !important;
    padding-right: 0px !important;
    background: #f8f8f8 !important;
    border-radius: 0px !important;
    max-width: 1080px !important;
    width: auto;
    box-shadow: none;
    position: relative;
  }

  .leg-list {
    margin: 0px auto;
    max-width: 441px;
  }
}

.map-button-show {
  display: block;
}

.map-button-hide {
  display: none;
}