.Loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
}
.Logo {
  margin: auto;
  height: 80px;
  width: 80px;
  display: block;
  fill: rgb(72, 72, 72);
}
