.input-time-outer-container {
  border: 1px solid #dbdbdb;
  display: block;
  border-radius: 4px;
}

.input-time-inner-container {
  margin: 0;
  padding: 0;
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  border-radius: 4px;
}

.input-time {
  font-weight: 200 !important;
  line-height: 1.44444em !important;
  color: #484848 !important;
  margin: 0px !important;
  font-size: 19px;
  padding: 11px 11px 9px;
  border: none;
  width: 100% !important;
  border-radius: 4px;
}

.input-time:focus {
  outline: 0;
}

.numeric-buttons-container {
  position: relative;
  display: table;
  width: 100%;
}

.numeric-button-container {
  height: 100px;
  width: 47%;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: rgb(235, 235, 235) !important;
  border-image: initial !important;
  border-radius: 4px !important;
  display: inline-block !important;
  text-align: center; // center the content of the container
  padding: 10px;
  min-width: 150px;
  cursor: pointer;
  margin-bottom: 10px;
}

.numeric-button-container-right {
  height: 100px;
  width: 47%;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: #ebebeb !important;
  border-image: initial !important;
  border-radius: 4px !important;
  text-align: center; // center the content of the container
  padding: 10px;
  float: right;
  min-width: 150px;
  cursor: pointer;
  margin-bottom: 10px;
}

.active {
  background: #008489;
}

.active p {
  color: #ffffff !important;
}

.inactive {
  background: #ffffff;
}

.inactive:only-child {
  color: #484848;
}

.numeric-button-sign-container {
  width: 33%;
  position: relative;
  display: inline-block;
  height: 100%;
}

.numeric-button-container-minus {
  height: 35px;
  width: 35px;
  float: left;
  margin-top: 10px;
}

.numeric-button-container-plus {
  height: 35px;
  width: 35px;
  float: right;
  margin-top: 10px;
}

.step-free-container-icon {
  margin-top: 8px;
  position: relative;
}

.numeric-button-container-icon {
  height: 40px;
  width: 40px;
}

.numeric-button-container-icon-box {
  margin-top: 10px;
  position: relative;
}

.numeric-step-free-button-text-box {
  position: relative;
  bottom: 10px;
}

.numeric-button-text-box {
  position: relative;
  bottom: 10px;
}

.numeric-button-text {
  line-height: initial;
  margin-block-end: 0em;
}

.numeric-button-bottom-text {
  line-height: initial;
  margin-block-start: 0em;
}

.switch {
  -webkit-box-align: center !important;
  text-align: justify !important;
  line-height: 0 !important;
  display: flex !important;
  flex-wrap: wrap !important;
  align-items: center !important;
}

.switch-button-outer-container {
  -webkit-box-flex: 1 !important;
  display: inline-block !important;
  vertical-align: middle !important;
  text-align: left !important;
  flex-grow: 1 !important;
}

.switch-button-inner-container {
  line-height: 0 !important;
  //display: inline-block !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: rgb(235, 235, 235) !important;
  border-image: initial !important;
  border-radius: 4px !important;
  float: right;
}

.switch-button {
  display: inline-block !important;
  vertical-align: middle !important;
  background-color: transparent !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  color: #767676 !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  cursor: pointer;
  border-width: 0px !important;
  border-style: none !important;
  border-image: none !important;
}

.switch-button:focus {
  outline: 0;
}

.switch-button-span-inactive {
  display: inline-block !important;
  vertical-align: middle !important;
  font-size: 14px !important;
  letter-spacing: normal !important;

  font-weight: 600 !important;
}

.switch-button-span-active {
  display: inline-block !important;
  vertical-align: middle !important;
  font-size: 14px !important;
  letter-spacing: normal !important;

  font-weight: 600 !important;
}

.switch-button-divider {
  display: inline-block !important;
  vertical-align: middle !important;
  height: 20px !important;
  border-left: 1px solid rgb(235, 235, 235) !important;
}

.modal-subtitle {
  font-size: 16px;
  font-weight: 600;
}

.modal-subtitle-icon {
  font-size: 16px;
  font-weight: 600;
  margin-left: 10px;
}

.title-custom-cursor {
  cursor: pointer;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.exclamation {
  color: red;
  animation: pulse 3s infinite;
}

.return-disabled {
  pointer-events: none;
  opacity: 0.5;
}

@media only screen and (max-width: 355px) {
  .numeric-button-container {
    margin-left: 25%;
    float: none;
  }

  .numeric-button-container-right {
    margin-left: 25%;
    float: none;
  }
}