.EditJourney {
  background: #ffffff !important;

  // -webkit-flex-direction: column !important;
  // -ms-flex-direction: column !important;
  // flex-direction: column !important;
  left: 0px !important;
  position: relative !important;
  right: 0px !important;

  z-index: 3 !important;
  margin-top: 54px;
  max-width: 441px;
  min-width: 330px !important;
  top: calc(var(--header-top-padding)) !important;
}

.EditJourney1 {
  -webkit-box-direction: normal !important;
  -webkit-box-orient: vertical !important;
  background: #ffffff !important;
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-direction: column !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  left: 0px !important;
  position: absolute !important;
  right: 0px !important;
  top: calc(54px + var(--header-top-padding)) !important;
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) !important;
  -moz-transition: transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) !important;
  transition: -ms-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    -webkit-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) !important;
  z-index: 3 !important;
  max-width: 441px;
  min-width: 330px !important;
}

.search {
  position: relative !important;
  z-index: 1 !important;
  border-bottom: 1px solid #f1f1f2;
  // padding: 0px 0px  !important;
  height: 111px;
  background-color: #ffffff !important;
  border-left: 1px solid #f1f1f2;
  border-right: 1px solid #f1f1f2;
}

.searchVia1 {
  //position: relative !important;
  z-index: 1 !important;
  border-bottom: 1px solid #f1f1f2;
  // padding: 0px 0px  !important;
  height: 165px !important;
  background-color: #ffffff !important;
  border-left: 1px solid #f1f1f2;
  border-right: 1px solid #f1f1f2;
}

.search-outer-container {
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  -webkit-align-items: center !important;
  align-items: center !important;
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
}

.search-inner-container-inactive {
  -webkit-box-align: stretch !important;
  -ms-flex-align: stretch !important;
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: stretch !important;
  align-items: stretch !important;
  height: 95px !important;
  width: 100% !important;
}

.search-inner-container-active {
  -webkit-box-align: stretch !important;
  display: flex !important;
  align-items: stretch !important;
  height: 95px !important;
  width: 100% !important;
  transform: translate3d(0px, 0px, 0px) !important;
}

.form {
  width: 100% !important;
  height: 100% !important;
  position: relative;
  display: block;
}

input:focus::placeholder {
  color: transparent;

  moz-placeholder {
    color: transparent;
  }

  -webkit-input-placeholder {
    color: transparent;
  }

  -ms-input-placeholder {
    color: transparent;
  }
}

input::placeholder {
  color: silver;
}

input:not([placeholder^="Enter location"]):not([placeholder^="Ort eingeben"]):not([placeholder^="Rhowch leoliad"]):not([placeholder^="Entrez la localisation"])::placeholder {
  color: #484848;
}


.form-input-inner-container {
  display: relative;
  padding-left: 22px;
  height: 55px;
}

.form-input-inner-content {
  line-height: 50px;
}

.form-input-inner-container-bottom {
  // vertical-align: 0%;
  display: relative;
  padding-left: 22px;
  height: 55px;
  // margin-top: 7px;
}

.form-input-label {
  transition: 0.2s;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.seperator {
  position: absolute;
  display: inline-block;
  top: 55px;
  left: 0px;
  width: 100%;
  height: 1px;
  background: #f1f1f2;
  display: inline-block;
}

.viaSeperator1 {
  position: absolute;
  display: inline-block;
  top: 110px;
  left: 0px;
  width: 100%;
  height: 1px;
  background: #f1f1f2;
  display: inline-block;
}

.viaSeperator2 {
  position: absolute;
  display: inline-block;
  top: 165px;
  left: 0px;
  width: 100%;
  height: 1px;
  background: #f1f1f2;
  display: inline-block;
}

.viaSeperator3 {
  position: absolute;
  display: inline-block;
  top: 220px;
  left: 0px;
  width: 100%;
  height: 1px;
  background: #f1f1f2;
  display: inline-block;
}

.header-seperator {
  position: absolute;
  display: inline-block;
  top: calc(53px + var(--header-top-padding));
  left: 0px;
  width: 100%;
  height: 1px;
  background: #f1f1f2;
  //border: 1px solid #f1f1f2;
  display: inline-block;
  z-index: 30;
}

.listbox {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch !important;
  background-color: rgb(255, 255, 255) !important;
  height: calc(1vh * 100 - 207px);
  left: 0px !important;
  padding-top: 8px !important;
  position: absolute !important;
  right: 0px !important;
  top: 100% !important;
  border-width: initial !important;
  border-style: none !important;
  border-color: initial !important;
  border-image: initial !important;
  border-radius: 0px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.listboxNoFooter {
  @extend .listbox;
  height: calc(1vh * 100 - 165px) !important;
}

.suggestion-container {
  width: 100% !important;
  height: 90px !important;
}

.listbox-ul {
  visibility: visible !important;
  opacity: 1 !important;
  margin: 0px !important;
  padding: 0px !important;
  transition: visibilty 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s,
    opacity 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s !important;
}

.listbox-li {
  list-style-type: none !important;
  margin: 0px !important;
  padding: 0px 0px 80px 0px !important;
  //padding: 0px !important;
}

.li-title-outer-container {
  margin: 16px 24px;
}

.li-title-inner-container {
  margin: 0px !important;
  word-wrap: break-word !important;

  font-size: 12px !important;
  font-weight: 800 !important;
  line-height: 1.3333333333333333em !important;
  color: #484848 !important;
}

.li-title {
  text-transform: uppercase !important;
}

.listbox-sub-ul {
  //overflow-x: scroll !important;
  white-space: nowrap !important;
  margin: 0px 0px 16px !important;
  padding: 0px 24px !important;
}

.listbox-suggestion-ul {
  //overflow-x: scroll !important;
  white-space: nowrap !important;
  margin: 0px 0px 16px !important;
  padding: 0px !important;
}

.listbox-suggestion-li {
  cursor: pointer !important;
  display: table !important;
  height: 60px !important;
  list-style-type: none !important;
  min-height: 60px !important;
  width: 100% !important;
  padding: 12px 24px !important;
  outline: none !important;
}

.listbox-suggestion-li-icon-container {
  color: rgb(72, 72, 72) !important;
  display: table-cell !important;
  padding-right: 12px !important;
  padding-top: 4px !important;
  vertical-align: top !important;
}

.listbox-suggestion-li-title-container {
  display: table-cell !important;
  vertical-align: top !important;
  width: 100% !important;
}

.listbox-suggestion-li-title {
  margin: 0px !important;
  word-wrap: break-word !important;

  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 1.2857142857142858em !important;
  color: #484848 !important;
  overflow-wrap: break-word !important;
  white-space: pre-wrap;
  /* CSS3 */
  white-space: -moz-pre-wrap;
  /* Firefox */
  white-space: -pre-wrap;
  /* Opera <7 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* IE */
}

.listbox-suggestion-li-subtitle {
  overflow-wrap: break-word !important;

  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.28571em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
  white-space: pre-wrap;
  /* CSS3 */
  white-space: -moz-pre-wrap;
  /* Firefox */
  white-space: -pre-wrap;
  /* Opera <7 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* IE */
}

.listbox-close-button {
  border: none;
  background: transparent;
  float: right;
  cursor: pointer;
}

.Close {
  height: 18px;
  width: 18px;
  display: block;
  fill: #484848;
}

.Search,
.Location,
.ChevronLeft,
.LocationOn {
  height: 18px;
  width: 18px;
  display: block;
  fill: rgb(72, 72, 72);
}

.Clear {
  height: 6px;
  width: 6px;
  display: block;
  fill: currentcolor;
}

.clear-button-container {
  -webkit-box-align: center !important;
  align-items: center !important;
  display: flex !important;
  position: absolute !important;
  right: 12px !important;
  top: 0px !important;
  bottom: 0px !important;
  height: 100%;
}

.clear-button {
  -webkit-box-pack: center !important;
  -webkit-box-align: center !important;
  align-items: center !important;
  color: rgb(255, 255, 255) !important;
  cursor: pointer !important;
  display: flex !important;
  height: 16px !important;
  justify-content: center !important;
  width: 16px !important;
  background: rgb(196, 197, 199) !important;
  border-width: 0px !important;
  border-style: initial !important;
  border-color: initial !important;
  border-image: initial !important;
  border-radius: 50% !important;
  padding: 0px !important;
}

.buffer {
  opacity: 0.01 !important;
  pointer-events: none !important;
  left: 0px !important;
  position: absolute !important;
  top: 100px !important;
  -webkit-transform: translateY(20px) !important;
  -ms-transform: translateY(20px) !important;
  transform: translateY(20px) !important;
  -webkit-transition: opacity 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    -webkit-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) !important;
  -moz-transition: opacity 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) !important;
  transition: opacity 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    -ms-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    -webkit-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) !important;
  width: 100% !important;
}

.swap-icon {
  display: inline-block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: silver;
  border: none;
  text-align: center;
  padding: 1px;
  transition: all 0.5s;
  // margin-left: -20px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 0px;
}

.plus-button {
  display: inline-block;
  height: 19px;
  width: 19px;
  border-radius: 50%;
  border: none;
  text-align: center;
  padding: 1px;
  transition: all 0.5s;
  // margin-left: -20px;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 10px;
}

.plusButtonClass {
  background: transparent;
  border: none !important;
  font-size: 0;
}

.cross-button {
  display: inline-block;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: none;
  text-align: center;
  padding: 1px;
  transition: all 0.5s;
  // margin-left: -20px;
  cursor: pointer;
  position: absolute;
  right: 17px;
  top: 5px;
  margin-top: 50px;
}

.crossButtonClass {
  background: transparent;
  border: none !important;
  cursor: pointer;
  font-size: 20px;
}

.swap-button-container {
  padding-top: 6px;
  background: transparent;
  height: 50px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 50px;
  z-index: 3;
  margin: 0;
}

.swap-button-container2 {
  padding-top: 6px;
  background: transparent;
  height: 50px;
  position: absolute;
  right: 10px;
  top: 63px;
  width: 50px;
  z-index: 3;
  margin: 0;
  opacity: 1;
}

.cross-button-container1 {
  padding-top: 6px;
  background: transparent;
  height: 50px;
  position: absolute;
  right: 5px;
  top: 120px;
  width: 50px;
  z-index: 3;
  margin: 0;
  display: none;
}

.cross-button-container2 {
  padding-top: 6px;
  background: transparent;
  height: 50px;
  position: absolute;
  right: 7px;
  top: 176px;
  width: 50px;
  z-index: 3;
  margin: 0;
  display: none;
}

.cross-button-container3 {
  padding-top: 6px;
  background: transparent;
  height: 50px;
  position: absolute;
  right: 7px;
  top: 231px;
  width: 50px;
  z-index: 3;
  margin: 0;
  display: none;
}

.swap-icon:focus {
  outline: 0;
}

.back-button {
  cursor: pointer !important;
  background-color: transparent !important;
  color: buttontext !important;
  border: 0px !important;
  display: block !important;
  padding: 0px;
  margin: 0px;
}

.button-outer-container {
  background-color: rgb(255, 255, 255) !important;
  bottom: 0px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  //position: absolute !important;
  width: 100% !important;
  height: 78px !important;
  line-height: 78px !important;
  border-top: 1px solid rgb(235, 235, 235) !important;
  //display: table !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-direction: column !important;
  flex-direction: column !important;
  left: 0px !important;
  position: fixed !important;
  right: 0px !important;
  max-width: 441px;
  //bottom: 42px !important;
}

.button-inner-container {
  display: table-cell !important;
  vertical-align: middle !important;
}

.cancel-button {
  display: inline-block !important;
  position: relative !important;
  text-align: center !important;
  transition-property: background, border-color, color !important;
  transition-duration: 0.2s !important;
  transition-timing-function: ease-out !important;
  cursor: pointer !important;
  width: 100% !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;
  font-family: inherit !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-weight: 800 !important;
  padding-left: 22px !important;
  padding-right: 22px !important;
  min-width: 71.1935px !important;
  box-shadow: none !important;
  color: rgb(0, 132, 137) !important;
  margin: 0px !important;
  text-decoration: none !important;
  border-radius: 4px !important;
  border-width: 2px !important;
  border-style: solid !important;
  background: transparent !important;
  border-color: rgb(0, 132, 137) !important;
}

.cancel-button-span {
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;
  font-family: inherit !important;
  text-transform: undefined !important;
  color: inherit !important;
  padding-top: undefined !important;
  padding-bottom: undefined !important;
  transition-duration: 0.2s !important;
}

.submit-button {
  visibility: hidden;
  position: absolute;
}

.ExpandMore {
  height: 18px;
  width: 18px;
  display: block;
  fill: rgb(72, 72, 72);
}

.filter-button-plus {
  font-weight: 600;
  line-height: 18px !important;
  letter-spacing: -0.2px !important;
  font-family: inherit !important;
  color: #008489 !important;
  padding-top: undefined !important;
  padding-bottom: undefined !important;
  background: none !important;
  border: 1px solid #008489 !important;
  border-radius: 6px !important;
  cursor: pointer !important;
  display: inline-block !important;
  margin: 0px !important;
  padding: 5px 8px 4px 8px !important;
  position: relative !important;
  text-align: center !important;
  text-decoration: none !important;
  -webkit-transition: background 0.3s, border-color 0.3s !important;
  -moz-transition: background 0.3s, border-color 0.3s !important;
  transition: background 0.3s, border-color 0.3s !important;
  // width: auto !important;
  background-color: #ffffff !important;
  width: 33px !important;
  height: 33px !important;
}

.filter-plus-span {
  font-size: 24px !important;
  // line-height: 18px !important;
  // padding-top: 4px;
  letter-spacing: normal !important;
  font-family: inherit !important;
  text-transform: undefined !important;
  padding-top: undefined !important;
  padding-bottom: undefined !important;
  color: #008489 !important;
}

.filter-button-container {
  display: inline-block;
  font-family: inherit !important;
  padding-left: 4px !important;
}

.filter-button-container-icon {
  height: 24px;
  width: 24px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -3px;
}

.menu-link-text {

  float: right;
  font-size: 16px !important;
  font-weight: 500 !important;
  padding-top: 3px;
}

.menu-link-text:hover {
  cursor: pointer;
}

.filter-button {
  font-size: 14px !important;
  font-weight: 500;
  line-height: 18px !important;
  letter-spacing: -0.2px !important;

  text-transform: undefined !important;
  padding-top: undefined !important;
  padding-bottom: undefined !important;
  border: 1px solid !important;
  border-radius: 6px !important;
  cursor: pointer !important;
  display: inline-block !important;
  margin: 0px !important;
  padding: 4px 4px !important;
  position: relative !important;
  text-align: center !important;
  text-decoration: none !important;
  -webkit-transition: background 0.3s, border-color 0.3s !important;
  -moz-transition: background 0.3s, border-color 0.3s !important;
  transition: background 0.3s, border-color 0.3s !important;
  width: auto !important;
  background-color: #ffffff;
  transition-duration: 0.2s !important;
}

.filter-button:focus {
  border-color: #484848 !important;
  box-shadow: inset 0 0 0 1px #484848 !important;
  outline: none !important;
}

.return-button {
  font-size: 14px !important;
  font-weight: 500;
  line-height: 18px !important;
  letter-spacing: -0.2px !important;

  text-transform: undefined !important;
  padding-top: undefined !important;
  padding-bottom: undefined !important;
  border: 1px solid !important;
  border-radius: 6px !important;
  cursor: pointer !important;
  display: inline-block !important;
  margin: 0px !important;
  padding: 4px 4px !important;
  position: relative !important;
  text-align: center !important;
  text-decoration: none !important;
  -webkit-transition: background 0.3s, border-color 0.3s !important;
  -moz-transition: background 0.3s, border-color 0.3s !important;
  transition: background 0.3s, border-color 0.3s !important;
  width: auto !important;
  transition-duration: 0.2s !important;
}

.return-button:focus {
  border-color: #484848 !important;
  box-shadow: inset 0 0 0 1px #484848 !important;
  outline: none !important;
}

.filter-button-span {
  color: #484848 !important;
  -webkit-transition: color 0.3s !important;
  -moz-transition: color 0.3s !important;
  transition: color 0.3s !important;
  -webkit-transition-timing-function: ease-in !important;
  transition-timing-function: ease-in !important;
}

.filter-scrollbox {
  // -webkit-overflow-scrolling: touch !important;
  display: flex;
  width: 100% !important;
  // overflow-y: hidden !important;
  margin-top: -3px;
  min-width: 300px;
}

.filter {
  height: 40px !important;
  z-index: 1 !important;
  width: 100%;
}

.float-right {
  float: right;
  align-items: flex-end;
}

.input-time-outer-container {
  border-radius: 2px;
  border: 1px solid #dbdbdb;
  display: block;
}

.input-time-inner-container {
  margin: 0;
  padding: 0;
  background: #fff;
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}

.input-time {

  font-weight: 200 !important;
  line-height: 1.44444em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
  font-size: 19px;
  background-color: #fff;
  width: 100%;
  padding: 11px 11px 9px;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0;
  border-radius: 0;
}

.input-time:focus {
  outline: 0;
}

.numeric-buttons-container {
  position: relative;
  display: table;
  width: 100%;
}

.numeric-button-container {
  height: 100px;
  width: 47%;
  background: #ffffff;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: rgb(235, 235, 235) !important;
  border-image: initial !important;
  border-radius: 4px !important;
  display: inline-block !important;
  text-align: center; // center the content of the container
  padding: 10px;
  min-width: 150px;
  cursor: pointer;
  margin-bottom: 10px;
}

.numeric-button-container-right {
  height: 100px;
  width: 47%;
  background: #ffffff;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: rgb(235, 235, 235) !important;
  border-image: initial !important;
  border-radius: 4px !important;
  text-align: center; // center the content of the container
  padding: 10px;
  float: right;
  min-width: 150px;
  cursor: pointer;
  margin-bottom: 10px;
}

.active {
  background: #008489;
}

.active p {
  color: #ffffff !important;
}

.inactive {
  background: #ffffff;
}

.inactive:only-child {
  color: #484848;
}

.numeric-button-sign-container {
  width: 33%;
  position: relative;
  display: inline-block;
  height: 100%;
}

.numeric-button-container-minus {
  height: 35px;
  width: 35px;
  float: left;
  margin-top: 10px;
}

.numeric-button-container-plus {
  height: 35px;
  width: 35px;
  float: right;
  margin-top: 10px;
}

.Step-free-container-icon {
  margin-top: 8px;
  position: relative;
}

.numeric-button-container-icon {
  height: 40px;
  width: 40px;
}

.numeric-button-container-icon-box {
  margin-top: 10px;
  position: relative;
}

.numeric-step-free-button-text-box {
  position: relative;
  bottom: 10px;
}

.numeric-button-text-box {
  position: relative;
  bottom: 10px;
}

.numeric-button-text {
  line-height: initial;
  margin-block-end: 0em;
}

.numeric-button-bottom-text {
  line-height: initial;
  margin-block-start: 0em;
}

.switch {
  -webkit-box-align: center !important;
  text-align: justify !important;
  line-height: 0 !important;
  display: flex !important;
  flex-wrap: wrap !important;
  align-items: center !important;
}

.switch-button-outer-container {
  -webkit-box-flex: 1 !important;
  display: inline-block !important;
  vertical-align: middle !important;
  text-align: left !important;
  flex-grow: 1 !important;
}

.switch-button-inner-container {
  line-height: 0 !important;
  //display: inline-block !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: rgb(235, 235, 235) !important;
  border-image: initial !important;
  border-radius: 4px !important;
  float: right;
}

.switch-button {
  display: inline-block !important;
  vertical-align: middle !important;
  background-color: transparent !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  color: rgb(118, 118, 118) !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  border-width: 0px !important;
  border-style: initial !important;
  border-color: initial !important;
  border-image: initial !important;
}

.switch-button:focus {
  outline: 0;
}

.switch-button-span-inactive {
  display: inline-block !important;
  vertical-align: middle !important;
  font-size: 14px !important;
  letter-spacing: normal !important;

  font-weight: 600 !important;
}

.switch-button-span-active {
  display: inline-block !important;
  vertical-align: middle !important;
  color: rgb(0, 132, 137);
  font-size: 14px !important;
  letter-spacing: normal !important;

  font-weight: 600 !important;
}

.switch-button-divider {
  display: inline-block !important;
  vertical-align: middle !important;
  height: 20px !important;
  border-left: 1px solid rgb(235, 235, 235) !important;
}

.Header {
  //position: sticky !important;

  height: 54px;
  -webkit-box-direction: normal !important;
  -webkit-box-orient: vertical !important;
  //box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) !important;
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-direction: column !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  left: 0px !important;
  position: absolute !important;
  right: 0px !important;
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) !important;
  -moz-transition: transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) !important;
  transition: -ms-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    -webkit-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) !important;
  z-index: 4 !important;
  min-width: 320px;
}

.header-outer-container {
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
  padding: 14px;
  height: 54px;
  text-align: left !important;
  background-color: #ffffff;
  width: 100%;
  min-width: 320px;
  border: 1px solid #f1f1f2;
}

.header-inner-container {
  margin: 0px !important;
  padding-left: 8px;
  word-wrap: break-word !important;

  line-height: 24px;
  letter-spacing: normal;
  font-weight: 700;
  font-size: 22px;
  width: 100%;
}

.left-navbar-buttons {
  flex-basis: 100px;
  flex-grow: 1;
  min-width: 320px;
  overflow-x: auto;
  white-space: nowrap;
}

.right-menu-button {
  flex-basis: 100px;
  flex-grow: 1;
  float: right;
  align-items: flex-end;
  padding-top: 3px;
}

.menu-button {
  // right: 0px !important;
  width: 31px !important;
  height: 31px !important;
}

.menu-button-container {
  border-radius: 6px;
  height: 33px;
  width: 33px;
  float: right;
}

.login-button {
  right: 0px !important;
  width: 31px !important;
  height: 31px !important;
}

.google-attribution {
  width: 125px;
  margin: 16px 24px;
}

.login-button-container {
  // border: 1px solid #008489;
  border-radius: 6px;
  height: 33px;
  width: 33px;
}

.warning-message {
  padding: 1em 1.2em 1em 1.2em;
  overflow-wrap: break-word !important;

  font-size: 14px !important;
  font-weight: 400 !important;
  color: rgb(72, 72, 72) !important;
}

.modal-subtitle {
  font-size: 16px;
  font-weight: 600;
}

@supports (--custom: properties) {
  .cancel-button {
    font-size: var(--font-button-font-size, 16px) !important;
    line-height: var(--font-button-line-height, 24px) !important;
    letter-spacing: var(--font-button-letter-spacing, normal) !important;

    text-transform: var(--font-button-text-transform, undefined) !important;
    padding-top: var(--spacing-button-vertical, 10px) !important;
    padding-bottom: var(--spacing-button-vertical, 10px) !important;
    font-weight: var(--font-bold-font-weight, 800) !important;
    padding-left: var(--spacing-button-horizontal, 22px) !important;
    padding-right: var(--spacing-button-horizontal, 22px) !important;
    box-shadow: var(--shadow-button-level0-box-shadow, none) !important;
    color: #008489 !important;
  }

  .cancel-button-span {
    font-size: var(--font-button-font-size, 16px) !important;
    line-height: var(--font-button-line-height, 24px) !important;
    letter-spacing: var(--font-button-letter-spacing, normal) !important;

    text-transform: var(--font-button-text-transform, undefined) !important;
    padding-top: var(--font-button-padding-top, undefined) !important;
    padding-bottom: var(--font-button-padding-bottom, undefined) !important;
  }
}

.show-1-itinerary {
  .swap-button {
    visibility: hidden;
  }

  .search {
    height: 165px;
  }

  .viaLocation1 {
    display: block;
  }

  .cross-button-container1 {
    display: block;
  }
}

.show-2-itinerary {
  .swap-button {
    visibility: hidden;
  }

  .search {
    height: 220px;
  }

  .viaLocation1 {
    display: block;
  }

  .viaLocation2 {
    display: block;
  }

  .cross-button-container2 {
    display: block;
  }
}

.show-3-itinerary {
  .swap-button {
    visibility: hidden;
  }

  .search {
    height: 275px;
  }

  .viaLocation1 {
    display: block;
  }

  .viaLocation2 {
    display: block;
  }

  .viaLocation3 {
    display: block;
  }

  .cross-button-container3 {
    display: block;
  }
}

.viaLocation1 {
  display: none;
}

.viaLocation2 {
  display: none;
}

.viaLocation3 {
  display: none;
}

// @media screen and (max-width: 767px) {
//   _::-webkit-full-page-media,
//   _:future,
//   :root .button-outer-container {
//     padding-bottom: 65px;
//   }
// }

@media (max-width: 600px) {
  .EditJourney {
    width: 100%;
    max-width: 600px;
    position: relative !important;
    //top: 54px !important;
  }

  .suggestion-container {
    width: 100% !important;
    height: 90px !important;
  }

  .listbox {
    height: calc(1vh * 100 - 169px) !important;
    //padding-bottom: 42px;
    overflow-x: hidden;
  }

  // .listbox-li {
  //   padding: 0px !important;
  // }

  .menu-button-container,
  .filter-button-container {
    padding-right: 2px;
  }
}

@media (max-width: 370px) {
  .hide-for-small {
    display: none;
  }
}

// /* Smartphones (portrait) ----------- */
// @media only screen and (max-width: 375px) {
//   .search {
//     height: 125px !important;
//   }

//   // .swap-icon {
//   //   top: 10px !important;
//   // }
// }