.Modal {
  z-index: 2000;
  position: absolute !important;
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  transform: translate3d(0px, 0px, 0px) !important;
  pointer-events: none !important;
  // overflow-y: hidden !important;
  background: none !important;
  max-width: 441px; //441px !important;
  margin-bottom: 42px;
}

.content {
  z-index: 2001;
  pointer-events: auto !important;
  background-color: rgb(255, 255, 255) !important;
  box-shadow: rgba(22, 22, 22, 0.2) 0px 0px 2px 0px !important;
  height: 100% !important;
  width: 100% !important;
  // overflow-y: hidden !important;
  position: relative !important;
  will-change: transform !important;
  border-top: 1px solid rgb(235, 235, 235) !important;
}

.icon-information {
  height: 20px;
  width: 35px;
  margin-left: -10px;
  float: left;
}

.edit-profile-text-span {
  word-wrap: break-word !important;

  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 1.2857142857142858em !important;
  color: #484848 !important;
  padding-top: 5px;
  margin-left: 20px;
  margin-right: 20px;
}

.header {
  height: 53px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  display: table !important;
  vertical-align: top !important;
  width: 100% !important;
  border-bottom: 1px solid rgb(235, 235, 235) !important;
}

.button-container {
  display: table-cell !important;
  text-align: left !important;
  vertical-align: middle !important;
  width: 5% !important;
}

.button {
  padding: 20px;
  margin: -20px;
  cursor: pointer !important;
  background-color: transparent !important;
  color: buttontext !important;
  border: 0px !important;
  display: block !important;
}

.Close {
  height: 18px;
  width: 18px;
  display: block;
  fill: rgb(72, 72, 72);
}

.title-container {
  display: table-cell !important;
  text-align: center !important;
  vertical-align: middle !important;
  width: 95% !important;
  padding-right: 40px;
}

.title {
  margin: 0px !important;
  padding-top: 2px;
  padding-left: 13px;
  word-wrap: break-word !important;

  line-height: 24px;
  letter-spacing: normal;
  font-weight: 700;
  font-size: 22px;
}

.action-container {
  display: table-cell !important;
  text-align: right !important;
  vertical-align: middle !important;
  width: 30% !important;
}

.action {
  overflow-wrap: break-word !important;

  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 1.28571em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
}

.main {
  // overflow-y: auto !important;
  overflow-x: hidden !important;
  width: 100% !important;
  position: absolute !important;
  top: 58px !important;
  bottom: 0px !important;
  padding: 0px 0px 5px 0px;
}

button:focus {
  outline: none !important;
}

.filter-button:hover {
  background: lightgray !important;
}

.filter-button2:hover {
  background: lightgray !important;
}

.filter-button-container {
  //vertical-align: middle !important;
  //margin-top: 10px;
  width: 90%;
  display: block;
  margin: 1em 1.2em 1em 1.2em !important;
}

.active span {
  color: #ffffff !important;
}

.inactive {
  background: #ffffff !important;
}

.copy-button-container {
  font-weight: 600 !important;
  padding-left: 5px !important;
  margin-right: -5px;
}

.edit-profile-button-container {
  //vertical-align: middle !important;
  //margin-top: 10px;
  //width: 100%;
  display: block;
  margin: 1em 1.2em 1em 1.2em !important;
}

.numeric-buttons-container {
  margin-left: 20px;
  position: relative;
  display: table;
  //width: 100%;
  max-width: 400px;
}

.numeric-button-sign-container {
  width: 33%;
  position: relative;
  display: inline-block;
  height: 100%;
}

.numeric-button-container-minus {
  height: 35px;
  width: 35px;
  float: left;
  margin-top: 10px;
}

.numeric-button-container {
  height: 100px;
  width: 47%;
  background: #ffffff;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: rgb(235, 235, 235) !important;
  border-image: initial !important;
  border-radius: 4px !important;
  display: inline-block !important;
  text-align: center; // center the content of the container
  padding: 10px;
  min-width: 150px;
  cursor: pointer;
  margin-bottom: 10px;
}

.numeric-button-container-right {
  height: 100px;
  width: 47%;
  background: #ffffff;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: rgb(235, 235, 235) !important;
  border-image: initial !important;
  border-radius: 4px !important;
  text-align: center; // center the content of the container
  padding: 10px;
  float: right;
  min-width: 150px;
  cursor: pointer;
  margin-bottom: 10px;
}

.numeric-button-container {
  height: 100px;
  width: 47%;
  background: #ffffff;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: rgb(235, 235, 235) !important;
  border-image: initial !important;
  border-radius: 4px !important;
  display: inline-block !important;
  text-align: center; // center the content of the container
  padding: 10px;
  min-width: 150px;
  cursor: pointer;
  margin-bottom: 10px;
}

.Step-free-container-icon {
  margin-top: 8px;
  position: relative;
}

.modal-subtitle {
  font-size: 16px;
  font-weight: 600;
  margin-left: 22px;
}

.modal-subtitle2 {
  font-size: 16px;
  font-weight: 600;
  margin-left: 4px;
}

.numeric-step-free-button-text-box {
  position: relative;
  bottom: 10px;
}

.numeric-button-bottom-text {
  line-height: initial;
  margin-block-start: 0em;
}

.numeric-button-text-box {
  position: relative;
  bottom: 10px;
}

.numeric-button-text {
  line-height: initial;
  margin-block-end: 0em;
}

.active {
  background: #008489;
}

.active p {
  color: #ffffff !important;
}

.inactive {
  background: #ffffff;
}

.inactive:only-child {
  color: #484848;
}

.numeric-button-container-icon {
  height: 40px;
  width: 40px;
}

.numeric-button-container-icon-box {
  margin-top: 10px;
  position: relative;
}

.numeric-button-sign-container {
  width: 33%;
  position: relative;
  display: inline-block;
  height: 100%;
}

.numeric-button-container-plus {
  height: 35px;
  width: 35px;
  float: right;
  margin-top: 10px;
}

.auth-required-text {
  text-align: center;
  color: red;
}

.update-profile-button {
  position: relative !important;
  text-align: center !important;
  transition-property: background, border-color, color !important;
  transition-duration: 0.2s !important;
  transition-timing-function: ease-out !important;
  cursor: pointer !important;
  //display: block !important;
  width: 97.5% !important;
  font-size: 14px !important;
  letter-spacing: normal !important;

  padding-top: 8px !important;
  padding-bottom: 8px !important;
  font-weight: 600 !important;
  padding-left: 22px !important;
  padding-right: 22px !important;
  min-width: 71.1935px !important;
  box-shadow: none !important;
  color: rgb(255, 255, 255);
  //margin: 0px 10px 0px 10px !important;
  text-decoration: none !important;
  border-radius: 4px 4px 4px 4px !important;
  border-right-width: 1px !important;
  border-left-width: 1px !important;
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
  border-style: solid !important;
  background: #ffffff;
  border-color: lightgrey !important;
}

.update-profile-button-span {
  font-size: var(--font-button-font-size, 16px) !important;
  line-height: var(--font-button-line-height, 24px) !important;
  letter-spacing: var(--font-button-letter-spacing, normal) !important;

  text-transform: var(--font-button-text-transform, undefined) !important;
  padding-top: var(--font-button-padding-top, undefined) !important;
  padding-bottom: var(--font-button-padding-bottom, undefined) !important;
}

.edit-profile-button {
  position: relative !important;
  text-align: center !important;
  transition-property: background, border-color, color !important;
  transition-duration: 0.2s !important;
  transition-timing-function: ease-out !important;
  cursor: pointer !important;
  //display: block !important;
  width: 97.5% !important;
  font-size: 14px !important;
  letter-spacing: normal !important;

  padding-top: 8px !important;
  padding-bottom: 8px !important;
  font-weight: 600 !important;
  padding-left: 22px !important;
  padding-right: 22px !important;
  min-width: 71.1935px !important;
  box-shadow: none !important;
  color: rgb(255, 255, 255);
  //margin: 0px 10px 0px 10px !important;
  text-decoration: none !important;
  border-radius: 4px 4px 4px 4px !important;
  border-right-width: 1px !important;
  border-left-width: 1px !important;
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
  border-style: solid !important;
  background: #ffffff;
  border-color: lightgrey !important;
}

.edit-profile-button-span {
  font-size: var(--font-button-font-size, 16px) !important;
  line-height: var(--font-button-line-height, 24px) !important;
  letter-spacing: var(--font-button-letter-spacing, normal) !important;

  text-transform: var(--font-button-text-transform, undefined) !important;
  padding-top: var(--font-button-padding-top, undefined) !important;
  padding-bottom: var(--font-button-padding-bottom, undefined) !important;
}

.filter-button {
  position: relative !important;
  text-align: center !important;
  transition-property: background, border-color, color !important;
  transition-duration: 0.2s !important;
  transition-timing-function: ease-out !important;
  cursor: pointer !important;
  //display: block !important;
  width: 50% !important;
  font-size: 14px !important;
  letter-spacing: normal !important;

  padding-top: 8px !important;
  padding-bottom: 8px !important;
  font-weight: 600 !important;
  padding-left: 22px !important;
  padding-right: 22px !important;
  min-width: 71.1935px !important;
  box-shadow: none !important;
  color: rgb(255, 255, 255);
  //margin: 0px 10px 0px 10px !important;
  text-decoration: none !important;
  border-radius: 4px 0px 0px 4px !important;
  border-right-width: 0px !important;
  border-left-width: 1px !important;
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
  border-style: solid !important;
  background: #ffffff;
  border-color: lightgrey !important;
}

.filter-button-span {
  font-size: var(--font-button-font-size, 16px) !important;
  line-height: var(--font-button-line-height, 24px) !important;
  letter-spacing: var(--font-button-letter-spacing, normal) !important;

  text-transform: var(--font-button-text-transform, undefined) !important;
  padding-top: var(--font-button-padding-top, undefined) !important;
  padding-bottom: var(--font-button-padding-bottom, undefined) !important;
}

.filter-button2 {
  position: relative !important;
  text-align: center !important;
  transition-property: background, border-color, color !important;
  transition-duration: 0.2s !important;
  transition-timing-function: ease-out !important;
  cursor: pointer !important;
  //display: block !important;
  width: 50% !important;
  font-size: 14px !important;
  letter-spacing: normal !important;

  padding-top: 8px !important;
  padding-bottom: 8px !important;
  font-weight: 600 !important;
  padding-left: 22px !important;
  padding-right: 22px !important;
  min-width: 71.1935px !important;
  box-shadow: none !important;
  color: rgb(255, 255, 255);
  //margin: 0px 10px 0px 10px !important;
  text-decoration: none !important;
  border-radius: 0px 4px 4px 0px !important;
  border-right-width: 1px !important;
  border-left-width: 0px !important;
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
  border-style: solid !important;
  background: #ffffff;
  border-color: lightgrey !important;
}

.filter-button-span2 {
  font-size: var(--font-button-font-size, 16px) !important;
  line-height: var(--font-button-line-height, 24px) !important;
  letter-spacing: var(--font-button-letter-spacing, normal) !important;

  text-transform: var(--font-button-text-transform, undefined) !important;
  padding-top: var(--font-button-padding-top, undefined) !important;
  padding-bottom: var(--font-button-padding-bottom, undefined) !important;
}

@media (max-width: 600px) {
  .Modal {
    width: 100% !important;
    max-width: 600px !important;
  }
}