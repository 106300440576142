.Confirmation {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  font-size: 14px !important;
  line-height: 18px !important;
  letter-spacing: normal !important;

  text-transform: undefined !important;
  color: #484848 !important;
  padding-top: undefined !important;
  padding-bottom: undefined !important;
  font-weight: normal !important;
}

.Done {
  margin: auto;
  height: 80px;
  width: 80px;
  display: block;
  fill: rgb(72, 72, 72);
}

.pre {
  overflow-wrap: break-word !important;
  white-space: pre-wrap !important;
  background-color: transparent !important;
  font: inherit !important;
  padding: 0px !important;
  margin: 0px !important;
  overflow: unset !important;
}

.pre-outer-container {
  margin-top: 16px;
}

.pre-inner-container {
  overflow-wrap: break-word !important;

  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 1.44444em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
}

.h2 {
  color: inherit !important;
  font-size: 1em !important;
  font-weight: inherit !important;
  line-height: inherit !important;
  margin: 0px !important;
  padding: 0px !important;
}

.header {
  font-size: 40px !important;
  line-height: 40px !important;
  letter-spacing: -1.5px !important;
  font-weight: 900 !important;
  color: rgb(51, 51, 51) !important;
}

.header-content {
  overflow-wrap: break-word !important;

  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 1.375em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
}

.section-outer-container {
  margin-bottom: 48px;
}

.span {
  font-size: 0px;
}

.prepend-container {
  margin-bottom: 16px;
}

.prepend {
  overflow-wrap: break-word !important;

  font-size: 14px !important;
  font-weight: 800 !important;
  line-height: 1.28571em !important;
  color: rgb(255, 231, 231) !important;
  margin: 0px !important;
}

.no-margin {
  margin: 0px;
}

.inner-container {
  display: block;
  margin-left: auto !important;
  margin-right: auto !important;
  width: auto !important;
  padding-right: 24px !important;
  padding-left: 24px !important;
}

.outer-container {
  width: 100%;
}

.outer-container::before {
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
  content: "" !important;
}

.outer-container::after {
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
  content: "" !important;
}

.a {
  color: rgb(0, 132, 137) !important;
  text-decoration-line: none !important;
  text-align: left !important;
  font: inherit inherit inherit inherit inherit inherit inherit inherit inherit !important;
}

.header-outer-container {
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
  padding: 14px;
  height: 54px;
  text-align: left !important;
  background-color: #f1f1f2;
}

@media (min-width: 744px) {
  .inner-container {
    max-width: 696px !important;
  }

  .section-outer-container {
    margin-bottom: 48px !important;
  }

  .section-inner-container {
    text-align: center !important;
  }

  .pre-mobile-container {
    max-width: 680px !important;
    margin: 0px auto !important;
  }
}

@media (min-width: 1128px) {
  .header {
    font-size: 60px !important;
    line-height: 64px !important;
    letter-spacing: -2.5px !important;
  }

  .inner-container {
    max-width: 1080px !important;
  }

  .section-outer-container {
    margin-bottom: 64px !important;
  }

  .pre-outer-containerteams {
    margin-top: 32px !important;
  }
}