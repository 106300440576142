.Confirmation {
  background-color: rgba(107, 107, 107, 0.4);
  position: fixed;
  height: 100%;
  z-index: 10;
  width: 100%;
  /* Firefox */
  display: -moz-box;
  -moz-box-pack: center;
  -moz-box-align: center;
  /* Safari and Chrome */
  display: -webkit-box;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  /* W3C */
  display: flex;
  box-pack: center;
  box-align: center;
}

.no-margin {
  margin: 0px;
}

.inner-container {
  display: block;
  margin-left: auto !important;
  margin-right: auto !important;
  width: auto !important;
  padding: 24px !important;
  background-color: white;
}

.outer-container {
  width: 70%;
  height: 50%;
  color: wheat;
}

.cross-site-image {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}

.span {
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;

  color: #484848 !important;
  margin-top: 10px;
}

.button {
  padding: 20px;
  margin: -20px;
  cursor: pointer !important;
  background-color: transparent !important;
  color: buttontext !important;
  border: 0px !important;
  display: block !important;
}

.button:active {
  outline: 0px !important;
}

.button-container {
  display: table-cell !important;
  text-align: left !important;
  vertical-align: middle !important;
  width: 5% !important;
}