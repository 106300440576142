.modal {
  z-index: 3;
  position: fixed !important;
  padding-top: var(--header-top-padding);
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  transform: translate3d(0px, 0px, 0px) !important;
  pointer-events: none !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  background: none !important;
  max-width: 441px !important;
}

.content {
  padding-top: 53px !important;
  z-index: 5;
  pointer-events: auto !important;
  background-color: rgb(255, 255, 255) !important;
  box-shadow: rgba(22, 22, 22, 0.2) 0px 0px 2px 0px !important;
  height: 100% !important;
  width: 100% !important;
  overflow-y: scroll !important;
  overflow-x: hidden;
  position: relative !important;
  will-change: transform !important;
  border-top: 1px solid rgb(235, 235, 235) !important;
}

.main {
  width: 100% !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  padding-top: 8px !important;
  padding-bottom: 140px !important;
  border-bottom: 0px !important;
  margin-top: 8px;
}

.main section>div {
  margin-bottom: 8px;
}

.header {
  padding: 10px;
  padding-right: 0px;
  padding-left: 0px;
  display: table !important;
  vertical-align: top !important;
  width: 100% !important;
  border-bottom: 1px solid rgb(235, 235, 235) !important;
}

.button-container {
  display: table-cell !important;
  text-align: right !important;
  vertical-align: middle !important;
  width: 5% !important;
}

.button {
  padding: 20px;
  margin: -20px;
  cursor: pointer !important;
  background-color: transparent !important;
  color: buttontext !important;
  border: 0px !important;
  display: block !important;
}

.button:active {
  outline: 0px !important;
}

.title-container {
  display: table-cell !important;
  text-align: center !important;
  vertical-align: left !important;
  width: 95% !important;
  margin-left: 10px;
}

.title {
  margin: 0px !important;
  padding-top: 2px;
  padding-left: 36px;
  word-wrap: break-word !important;

  line-height: 24px;
  letter-spacing: normal;
  font-weight: 700;
  font-size: 22px;
}

.footer {
  z-index: 1002;
  bottom: 39px; // TA-2906
  background-color: white !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  position: absolute !important; //TA-2906
  width: 100% !important;
  height: 78px !important;
  line-height: 78px !important;
  display: table !important;
  border-top: 1px solid rgb(235, 235, 235) !important; //TA-2906
}

.confirm-button-container {
  display: table-cell !important;
  vertical-align: middle !important;
}

.confirm-button {
  pointer-events: auto !important;
  position: relative !important;
  text-align: center !important;
  transition-property: background, border-color, color !important;
  transition-duration: 0.2s !important;
  transition-timing-function: ease-out !important;
  cursor: pointer !important;
  display: block !important;
  width: 100% !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;

  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-weight: 800 !important;
  padding-left: 22px !important;
  padding-right: 22px !important;
  min-width: 71.1935px !important;
  box-shadow: none !important;
  color: rgb(255, 255, 255) !important;
  margin-top: 0px !important;
  text-decoration: none !important;
  border-radius: 4px !important;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: transparent !important;
}

.confirm-span {
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;

  color: inherit !important;
}

.action-container {
  display: table-cell !important;
  text-align: right !important;
  vertical-align: middle !important;
  width: 30% !important;
}

.action {
  overflow-wrap: break-word !important;

  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 1.28571em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
}


.close {
  height: 18px;
  width: 18px;
  display: block;
  margin-right: 30px;
  fill: rgb(72, 72, 72);
}

@media (max-width: 600px) {
  .modal {
    width: 100% !important;
    max-width: 600px !important;
  }
}