.a {
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  font-style: inherit !important;
  line-height: inherit !important;
  text-decoration-line: none !important;
  font-variant: inherit !important;
  padding: 0 !important;
  margin: 0;
  border: none !important;
  background: transparent;
  outline: none;
  cursor: pointer;
}

.show-directions-button {
  font-weight: 600 !important;
  font-size: 14px !important;
  font-style: inherit !important;
  line-height: inherit !important;
  color: rgb(0, 132, 137);
  text-decoration-line: none !important;
  font-variant: inherit !important;
  padding: 0 !important;
  margin: 0;
  border: none !important;
  background: transparent;
  outline: none;
}

.input[type="time"] {
  margin: 0px !important;
  word-wrap: break-word !important;
  line-height: 1.375em !important;
  color: #484848 !important;
}

.Walk,
.Rail,
.Ferry,
.Bus,
.Tram,
.Coach,
.Metro,
.Underground,
.Drive,
.Cycle,
.Destination,
.Plane,
.EvStation,
.ElectricVehicle,
.Park {
  height: 18px;
  width: 18px;
  display: block;
  fill: rgb(72, 72, 72);
}

.IntermediateStopsWrapper {
  position: relative;
  margin-top: 20px;
}

.IntermediateStopsWrapper::before {
  content: "";
  position: absolute;
  left: 27px;
  top: 0;
  bottom: 0;
  width: 8px;
}



.IntermediateStopsList {
  list-style: none;
  margin-bottom: 0px;
}

.intermediateStopsItem {
  position: relative;
  margin-bottom: 30px;
}

.intermediateStopsItem:last-child {
  margin-bottom: 20px;
}

.stationName {
  position: relative;
  font-size: 16px;
  color: #333;
}

.stationName::before {
  content: "";
  position: absolute;
  left: -37.5px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--primary-color);
}

.content-wrapper {
  display: flex;
  margin-left: 17px;
}

.green-column-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: -13px;
}


.green-column {
  width: 5px;
  flex: 1;
}

.modal-content {
  flex: 1;
  padding-left: 10px;
}

.stops-list-section {
  margin-top: 20px;

  h3 {
    margin-bottom: 10px;
    font-size: 1.1rem;
    color: #333;
  }
}

.stationInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.stationDepartureTime {
  font-size: 14px;
  color: #333;
}