.Loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
}

.LoadingTextContainer {
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 10px;
  width: 320px;

  @media (min-width: 576px) {
    padding: 0;
    width: 450px;
  }

  @media (min-width: 768px) {}
}

.LoadingText {
  color: #484848;
  font-size: 32px;

  @media (min-width: 576px) {
    font-size: 34px;
  }

  @media (min-width: 768px) {}
}