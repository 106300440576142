.numeric-button-container {
  height: 110px;
  width: 47%;
  background: #ffffff;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: rgb(235, 235, 235) !important;
  border-image: initial !important;
  border-radius: 4px !important;
  display: inline-block !important;
  text-align: center; // center the content of the container
  padding: 10px;
  min-width: 150px;
  cursor: pointer;
  margin-bottom: 10px;
}

.numeric-button-container-right {
  height: 110px;
  width: 47%;
  background: #ffffff;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: rgb(235, 235, 235) !important;
  border-image: initial !important;
  border-radius: 4px !important;
  text-align: center; // center the content of the container
  padding: 10px;
  float: right;
  min-width: 150px;
  cursor: pointer;
  margin-bottom: 10px;
}

.active span {
  color: #ffffff !important;
  display: block;
  margin-top: 13px;
  margin-bottom: 7px;
  padding-left: 4px;
  padding-right: 4px;
}

.inactive {
  background: #ffffff;
}

.inactive span {
  color: #404040;
  display: block;
  margin-top: 13px;
  margin-bottom: 7px;
  padding-left: 4px;
  padding-right: 4px;
}

.numeric-button-sign-container {
  width: 33%;
  position: relative;
  display: inline-block;
  height: 100%;
}

.numeric-button-container-minus {
  height: 35px;
  width: 35px;
  float: left;
  margin-top: 10px;
  border-radius: 50%;
  background-color: white;
}

.numeric-button-container-plus {
  height: 35px;
  width: 35px;
  float: right;
  margin-top: 10px;
  border-radius: 50%;
  background-color: white;
}

.numeric-button-container-icon {
  height: 40px;
  width: 40px;
}

.numeric-button-container-icon-box {
  margin-top: 0px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.numeric-button-text-box {
  position: relative;
  bottom: 10px;
}

.modal-subtitle-icon {
  font-size: 16px;
  font-weight: 600;
  margin-left: 32px;
}

.numeric-button-container-middle-text {
  width: auto;
  margin-top: 10px;
}

.numeric-button-text-header {
  position: relative;
  align-items: center;
  display: flex;
  width: auto;
  height: 10px;
  justify-content: center;
}