.Modal {
  z-index: 2000;
  position: fixed !important;
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  transform: translate3d(0px, 0px, 0px) !important;
  pointer-events: none !important;
  // overflow-y: hidden !important;
  background: none !important;
  max-width: 441px; //441px !important;
  margin-bottom: 42px;
}

.content {
  z-index: 2001;
  pointer-events: auto !important;
  background-color: rgb(255, 255, 255) !important;
  box-shadow: rgba(22, 22, 22, 0.2) 0px 0px 2px 0px !important;
  height: 100% !important;
  width: 100% !important;
  // overflow-y: hidden !important;
  position: relative !important;
  will-change: transform !important;
  border-top: 1px solid rgb(235, 235, 235) !important;
}

.icon-information {
  height: 20px;
  width: 35px;
  margin-left: -10px;
  float: left;
}

.padding {
  padding-top: 8px;
  padding-bottom: 8px;
}

.menu-link-text {
  color: #484848 !important;
  text-decoration: none;

  font-size: 16px !important;
  font-weight: 500 !important;
}

.menu-link-text:hover {
  cursor: pointer;
}

.edit-profile-text-span {
  word-wrap: break-word !important;

  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 1.2857142857142858em !important;
  color: #484848 !important;
  padding-top: 5px;
  margin-left: 20px;
  margin-right: 20px;
}

.header {
  height: 53px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  display: table !important;
  vertical-align: top !important;
  width: 100% !important;
}

.button-container {
  display: table-cell !important;
  text-align: left !important;
  vertical-align: middle !important;
  width: 5% !important;
}

.button {
  padding: 20px;
  margin: -20px;
  cursor: pointer !important;
  background-color: transparent !important;
  color: buttontext !important;
  border: 0px !important;
  display: block !important;
}

.Close {
  height: 18px;
  width: 18px;
  display: block;
  fill: rgb(72, 72, 72);
}

.title-container {
  display: table-cell !important;
  text-align: center !important;
  vertical-align: middle !important;
  width: 95% !important;
  padding-right: 40px;
}

.title {
  margin: 0px !important;
  padding-top: 2px;
  padding-left: 13px;
  word-wrap: break-word !important;

  line-height: 24px;
  letter-spacing: normal;
  font-weight: 700;
  font-size: 22px;
}

.action-container {
  display: table-cell !important;
  text-align: right !important;
  vertical-align: middle !important;
  width: 30% !important;
}

.action {
  overflow-wrap: break-word !important;

  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 1.28571em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
}

.main {
  // overflow-y: auto !important;
  overflow-x: hidden !important;
  width: 100% !important;
  position: absolute !important;
  top: 58px !important;
  bottom: 0px !important;
  padding: 0px 0px 5px 0px;
}

button:focus {
  outline: none !important;
}

.filter-button:hover {
  background: lightgray !important;
}

.filter-button-container {
  //vertical-align: middle !important;
  //margin-top: 10px;
  width: 90%;
  display: block;
  margin: 1em 1.2em 1em 1.2em !important;
}

.active {
  background: rgb(0, 132, 137) !important;
}

.active span {
  color: #ffffff !important;
}

.inactive {
  background: #ffffff !important;
}

.inactive span {
  color: rgb(0, 132, 137) !important;
}

@media (max-width: 600px) {
  .Modal {
    width: 100% !important;
    max-width: 600px !important;
  }
}