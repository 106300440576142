.Walk,
.Rail,
.Ferry,
.Bus,
.Tram,
.Coach,
.Metro,
.Underground,
.Drive,
.Cycle,
.Destination,
.Plane,
.EvStation,
.ElectricVehicle,
.Search,
.Location,
.ChevronLeft,
.LocationOn {
  height: 18px;
  width: 18px;
  display: block;
  fill: rgb(72, 72, 72);
}