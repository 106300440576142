.Event {
  height: 100%;
  position: absolute;
  width: 100%;
  background: #fff;
  flex-flow: column;
  display: -webkit-flex;
  display: flex;
  //overflow: scroll;
  width: 100%;
}

.MapWrapper {
  // display: -webkit-box !important;
  // display: -moz-box !important;
  // display: -ms-flexbox !important;
  // display: -webkit-flex !important;
  position: relative !important;
  display: inline-grid;
  height: 100%;
  width: 100%;
  padding: 0px;
  overflow: hidden;
  // bottom: 42px;
}

.clustering-functions-frame {
  bottom: 60px;
  right: 50px;
  background: #fff;
  width: 100px;
  height: auto;
  padding: 5px;
  position: fixed !important;
}

.map-lock,
.journey-map-lock {
  width: 46px;
  height: auto;
  position: relative;
}

.right-side-bar {
  width: 46px;
  height: auto;
  position: absolute;
  top: calc(60px + var(--header-top-padding));
  right: 15px;
}

.map-lock:hover,
.journey-map-lock:hover {
  cursor: pointer;
}

.footer-functions {
  visibility: hidden;
}

.ev-visibility {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 140px;
  right: 8px;
}

// .clear {
//   clear: both;
//   overflow: hidden;
// }

.mapPlaceholderImage {
  display: flex !important;
  position: absolute;
  top: calc(50% - 150px);
  left: calc(50% - 150px);
  width: 300px;
  height: 300px;
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 4s, opacity 2s linear;
}

.JourneyMap {
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
  left: 441px !important;
  position: fixed !important;
  right: 0px !important;
  height: 100vh !important;
  min-width: 300px;
}

.LocationOn {
  height: 40px;
  width: 40px;
  display: flex;
  margin-bottom: 40px;
  cursor: pointer;
  transition: 0.3s ease;
  transform-origin: bottom center;
  animation: popup 0.5s ease;
}

.LocationOn:hover {
  transform: scale(1.1);
}

.popup-button {
  position: relative !important;
  text-align: center !important;
  transition-property: background, border-color, color !important;
  transition-duration: 0.2s !important;
  transition-timing-function: ease-out !important;
  cursor: pointer !important;
  display: block !important;
  width: 100% !important;
  font-size: 15px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;

  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-weight: 700 !important;
  padding-left: 22px !important;
  padding-right: 22px !important;
  min-width: 71.1935px !important;
  box-shadow: none !important;
  color: white !important;
  margin: 0px;
  margin-top: 10px;
  text-decoration: none !important;
  border-radius: 4px !important;
  border-width: 2px !important;
  border-style: solid !important;
  background: #008489 !important;
  border-color: transparent !important;
}

.popup-button:hover {
  background: #00b9bf !important;
}

.buffer {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background: transparent !important;
  border: 0px !important;
  display: block !important;
  margin: 0px !important;
  outline: none !important;
  width: 100% !important;
  z-index: -1 !important;
  padding-bottom: 20px;
  // min-height: 127px !important;
}

.journeyCollection {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background: transparent !important;
  border: 0px !important;
  display: block !important;
  margin: 0px !important;
  outline: none !important;
  width: 100% !important;
  z-index: -1 !important;
  padding-top: 50px;
  // min-height: 229px !important;
}

.fullscreen-button {
  display: none;
}

@media only screen and (max-width: 600px) {
  .JourneyMap {
    padding: 0 !important;
    margin: 0 !important;
    left: 0px !important;
    top: 0px !important;
    position: relative !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    height: 300px !important;
    width: 100% !important;
  }

  .JourneyMapFullscreen {
    padding: 0 !important;
    margin: 0 !important;
    left: 0px !important;
    top: 0px !important;
    position: relative !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    height: 610px !important;
    width: 100% !important;
  }

  .JourneyMapWrapper {
    width: 100% !important;
    left: 0 !important;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    position: relative !important;
    display: flex !important;
    position: relative !important;
    right: 0 !important;
  }

  .Map {
    padding: 0 !important;
    margin: 0 !important;
    left: 0px !important;
    top: 0px !important;
    position: relative !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    height: 100% !important;
    width: 100% !important;
  }

  .MapWrapper {
    margin-top: 111px;
  }

  .right-side-bar-traffic {
    width: 46px;
    height: auto;
    position: absolute;
    top: 170px;
    right: 15px;
  }

  .journey-map-lock {
    top: 60px;
  }

  .right-side-bar {
    width: 46px;
    height: auto;
    position: absolute;
    top: calc(175px + var(--header-top-padding));
    right: 15px;
  }

  .right-side-bar-show-travel {
    top: calc(240px + var(--header-top-padding))
  }

  .s {
    height: 0 !important;
    min-height: 0px !important;
    padding-top: 0px;
  }

  .journey-map-lock {
    top: 10px;
  }

  .Event {
    position: relative !important;
    height: 100vh !important;
    // min-height: 100vh !important;
    left: 0px !important;
    width: auto !important;
    // overflow-y: scroll !important;
    overflow-x: hidden !important;
    -webkit-overflow-scrolling: touch !important;
  }

  .fullscreen-button {
    margin-top: 5px;
    display: none; // TODO: Enable this button
  }

  // .Map {
  //   height: calc(100vh - 42px);
  // }
}

.Icon {
  height: 20px;
  width: 20px;
  fill: #000000;
  // animation: popup 0.5s ease;
}

@keyframes popup {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.Flight {
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 50%;
  fill: #000000;
  z-index: 1000;
  // animation: popup 0.5s ease; // Disable the animation until it isn't bugged :)
}

//This CSS block is developed for a bug TA-1238  hiding and revealing toolbar on safari was blocking the filter part

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .Event {
      height: -webkit-fill-available !important;
    }
  }
}

@media only screen and (max-width: 800px) {
  .footer-functions {
    visibility: visible;
  }
}