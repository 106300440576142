.Modal {
  z-index: 2000;
  padding-top: var(--header-top-padding);
  position: fixed !important;
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  transform: translate3d(0px, 0px, 0px) !important;
  pointer-events: none !important;
  overflow-y: hidden !important;
  background: none !important;
  max-width: 441px !important;
  margin-bottom: 42px;
}

.content {
  z-index: 2001;
  pointer-events: auto !important;
  background-color: rgb(255, 255, 255) !important;
  box-shadow: rgba(22, 22, 22, 0.2) 0px 0px 2px 0px !important;
  height: 100% !important;
  width: 100% !important;
  overflow-y: hidden !important;
  position: relative !important;
  will-change: transform !important;
  border-top: 1px solid rgb(235, 235, 235) !important;
}

.header {
  height: 53px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  display: table !important;
  vertical-align: top !important;
  width: 100% !important;
  border-bottom: 1px solid rgb(235, 235, 235) !important;
}

.button-container {
  display: table-cell !important;
  text-align: left !important;
  vertical-align: middle !important;
  width: 5% !important;
}

.share-buttons {
  display: inline-block;
  width: 100%;
  height: 60px;
  margin: 0px 0px 40px 0px;
}

.share-button {
  display: inline-block;
  width: 16%;
  max-height: 70px;
}

.icon-seperator {
  display: inline-block;
  width: 5%;
}

.share-buttons:last-child {
  margin-right: 0%;
}

.input-container {
  position: relative !important;
  overflow: hidden !important;
  width: 100%;
  margin-bottom: 8px;
}

.non-editable-textarea {
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;

  color: gray !important;
  padding-top: 11px !important;
  padding-bottom: 11px !important;
  font-weight: normal !important;
  background-color: transparent !important;
  padding-left: 11px !important;
  padding-right: 11px !important;
  width: 100% !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-radius: 4px !important;
  border-color: lightgray;
  margin: 0px !important;
}

.short {
  height: 50px;
}

.long {
  height: 130px;
  overflow: hidden;
}

.button {
  padding: 20px;
  margin: -20px;
  cursor: pointer !important;
  background-color: transparent !important;
  color: buttontext !important;
  border: 0px !important;
  display: block !important;
}

.button:active {
  outline: 0px !important;
}

.copy-button-container {
  vertical-align: middle !important;
}

.title-container {
  display: table-cell !important;
  text-align: center !important;
  vertical-align: middle !important;
  width: 95% !important;
  padding-right: 40px;
}

.title {
  margin: 0px !important;
  padding-top: 2px;
  padding-left: 8px;
  word-wrap: break-word !important;

  line-height: 24px;
  letter-spacing: normal;
  font-weight: 700;
  font-size: 22px;
}

.main {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  width: 100% !important;
  position: absolute !important;
  top: 58px !important;
  bottom: 0px !important;
  padding: 15px 20px 5px 20px;
}

.main-content {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.section-container {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border-bottom: 0px !important;
}

.embed-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.embed-text {
  margin: 0px !important;
  word-wrap: break-word !important;

  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 1.2857142857142858em !important;
  color: #484848 !important;
}

.footer {
  background-color: rgb(255, 255, 255) !important;
  bottom: 42px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  position: absolute !important;
  width: 100% !important;
  height: 78px !important;
  line-height: 78px !important;
  display: table !important;
  border-top: 1px solid rgb(235, 235, 235) !important;
}

.confirm-button-container {
  display: table-cell !important;
  vertical-align: middle !important;
}

.confirm-button {
  position: relative !important;
  text-align: center !important;
  transition-property: background, border-color, color !important;
  transition-duration: 0.2s !important;
  transition-timing-function: ease-out !important;
  cursor: pointer !important;
  display: block !important;
  width: 100% !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;

  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-weight: 800 !important;
  padding-left: 22px !important;
  padding-right: 22px !important;
  min-width: 71.1935px !important;
  box-shadow: none !important;
  color: rgb(255, 255, 255) !important;
  margin: 0px !important;
  text-decoration: none !important;
  border-radius: 4px !important;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: transparent !important;
}

.confirm-span {
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;

  color: inherit !important;
}

.api-button {
  position: relative !important;
  text-align: center !important;
  transition-property: background, border-color, color !important;
  transition-duration: 0.2s !important;
  transition-timing-function: ease-out !important;
  cursor: pointer !important;
  display: block !important;
  width: 100% !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;

  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-weight: 800 !important;
  padding-left: 22px !important;
  padding-right: 22px !important;
  min-width: 71.1935px !important;
  box-shadow: none !important;
  color: #e0e0e0 !important;
  margin: 0px !important;
  text-decoration: none !important;
  border-radius: 4px !important;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: transparent !important;
}

.action-container {
  display: table-cell !important;
  text-align: right !important;
  vertical-align: middle !important;
  width: 30% !important;
}

.action {
  overflow-wrap: break-word !important;

  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 1.28571em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
}

.section-container-margin {
  margin-top: 8px;
}

.section-container section>div {
  margin-bottom: 8px;
}

.Close {
  height: 18px;
  width: 18px;
  display: block;
  fill: rgb(72, 72, 72);
}

.button-link {
  color: #ffffff;
  text-decoration: none;
}

@supports (--custom: properties) {
  .confirm-button {
    font-size: var(--font-button-font-size, 16px) !important;
    line-height: var(--font-button-line-height, 24px) !important;
    letter-spacing: var(--font-button-letter-spacing, normal) !important;

    text-transform: var(--font-button-text-transform, undefined) !important;
    padding-top: var(--spacing-button-vertical, 10px) !important;
    padding-bottom: var(--spacing-button-vertical, 10px) !important;
    font-weight: var(--font-bold-font-weight, 800) !important;
    padding-left: var(--spacing-button-horizontal, 22px) !important;
    padding-right: var(--spacing-button-horizontal, 22px) !important;
    box-shadow: var(--shadow-button-level0-box-shadow, none) !important;
    color: #ffffff !important;
  }

  .confirm-button-span {
    font-size: var(--font-button-font-size, 16px) !important;
    line-height: var(--font-button-line-height, 24px) !important;
    letter-spacing: var(--font-button-letter-spacing, normal) !important;

    text-transform: var(--font-button-text-transform, undefined) !important;
    padding-top: var(--font-button-padding-top, undefined) !important;
    padding-bottom: var(--font-button-padding-bottom, undefined) !important;
  }

  .api-button {
    font-size: var(--font-button-font-size, 16px) !important;
    line-height: var(--font-button-line-height, 24px) !important;
    letter-spacing: var(--font-button-letter-spacing, normal) !important;

    text-transform: var(--font-button-text-transform, undefined) !important;
    padding-top: var(--spacing-button-vertical, 10px) !important;
    padding-bottom: var(--spacing-button-vertical, 10px) !important;
    font-weight: var(--font-bold-font-weight, 800) !important;
    padding-left: var(--spacing-button-horizontal, 22px) !important;
    padding-right: var(--spacing-button-horizontal, 22px) !important;
    box-shadow: var(--shadow-button-level0-box-shadow, none) !important;
    color: #484848 !important;
  }

  .api-button-span {
    font-size: var(--font-button-font-size, 16px) !important;
    line-height: var(--font-button-line-height, 24px) !important;
    letter-spacing: var(--font-button-letter-spacing, normal) !important;

    text-transform: var(--font-button-text-transform, undefined) !important;
    padding-top: var(--font-button-padding-top, undefined) !important;
    padding-bottom: var(--font-button-padding-bottom, undefined) !important;
  }
}

@media (max-width: 600px) {
  .Modal {
    width: 100% !important;
    max-width: 600px !important;
  }
}