.pre {
  overflow-wrap: break-word !important;
  white-space: pre-wrap !important;
  background-color: transparent !important;
  font: inherit !important;
  padding: 0px !important;
  margin: 0px !important;
  overflow: unset !important;
}

.pre-outer-container {
  margin-top: 16px;
}

.pre-inner-container {
  overflow-wrap: break-word !important;

  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 1.44444em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
}

.h2 {
  color: inherit !important;
  font-size: 1em !important;
  font-weight: inherit !important;
  line-height: inherit !important;
  margin: 0px !important;
  padding: 0px !important;
}

.header {
  font-size: 40px !important;
  line-height: 40px !important;
  letter-spacing: -1.5px !important;
  font-weight: 900 !important;
  color: rgb(51, 51, 51) !important;
}

.header-content {
  overflow-wrap: break-word !important;

  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 1.375em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
}

.a {
  color: rgb(0, 132, 137) !important;
  text-decoration-line: none !important;
  text-align: left !important;
  font: inherit inherit inherit inherit inherit inherit inherit inherit inherit !important;
}

/* The Modal (background) */
.modal {
  display: block;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 100;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: hidden;
  /* Disable scroll */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 20% auto;
  /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

@media (min-width: 744px) {
  .inner-container {
    max-width: 696px !important;
  }

  .section-outer-container {
    margin-bottom: 48px !important;
  }

  .section-inner-container {
    text-align: center !important;
  }

  .pre-mobile-container {
    max-width: 680px !important;
    // margin: 0px auto !important;
  }
}

@media (min-width: 1128px) {
  .header {
    font-size: 60px !important;
    line-height: 64px !important;
    letter-spacing: -2.5px !important;
  }

  .inner-container {
    max-width: 1080px !important;
  }

  .section-outer-container {
    margin-bottom: 64px !important;
  }

  .pre-outer-containerteams {
    margin-top: 32px !important;
  }
}