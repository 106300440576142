.Footer {
  bottom: 0px;
  -webkit-box-direction: normal !important;
  -webkit-box-orient: vertical !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) !important;
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-direction: column !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  left: 0px !important;
  position: fixed !important;
  overflow: hidden;
  right: 0px !important;
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) !important;
  -moz-transition: transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) !important;
  transition: -ms-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    -webkit-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) !important;
  z-index: 10000 !important;
}

.footer-outer-container {
  padding: 12px;
  width: 100%;
  height: 42px;
  text-align: center !important;
  background-color: #ffffff;
}

.footer-inner-container {
  margin: 0px !important;
  word-wrap: break-word !important;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  height: 100vh;
  line-height: 1.3333333333333333em !important;
  margin: auto;
  width: 100%;
  display: flex;
}

.footer-inner-left {
  min-width: 130px;
}



.footer-inner-center {
  flex-grow: 1;
  flex-shrink: 5;
  white-space: nowrap;
}

.footer-inner-right {
  position: absolute;
  display: block;
  right: 0px;
  margin-right: 15px;
  float: right;
}

.footer-inner-right-span {
  vertical-align: top;
  overflow-wrap: break-word !important;

  font-size: inherit !important;
  font-weight: 600 !important;
  line-height: 1.44444em !important;
  color: #484848 !important;
  margin-right: 3px;
}

.footer-inner-right-span:hover {
  cursor: pointer;
  text-decoration: underline;
}

.footer-logo {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.footer-strong {
  overflow-wrap: break-word !important;

  font-size: inherit !important;
  font-weight: 600 !important;
  line-height: 1.44444em !important;
  color: #484848;
  margin: 0px !important;
}

.footer-a {
  text-decoration: none;
  outline: none;
  margin-left: 3px;
}

.footer-a:hover {
  cursor: pointer;
  text-decoration: none;
  color: rgb(0, 132, 137) !important;
}

.link-text {
  overflow-wrap: break-word !important;
  text-decoration: none;
  font-size: inherit;
  color: #484848;
  font-weight: 600 !important;
  line-height: 1.44444em !important;
}

.link-text:hover {
  cursor: pointer;
  text-decoration: none;
  color: #008489 !important;
}

.link-text-custom {
  overflow-wrap: break-word !important;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600 !important;
  line-height: 1.44444em !important;
}

.link-text-custom:hover {
  color: var(--hover-color) !important;
  cursor: pointer;
  text-decoration: none;
}

.footer-inner-left-text {
  white-space: nowrap;
}

@media (max-width: 600px) {
  .footer-inner-left-text {
    display: none !important;
  }
}





;