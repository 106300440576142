.clustering-marker-style-onedigit {
  width: 25px;
  height: 25px;
  color: #fff;
  padding-top: 2.5px;
  border-radius: 20px;
  text-align: center;
  font-style: bold;
  font-size: 17px;
  background-color: #000;
  cursor: pointer;
  transition: 0.3s ease;
}

.clustering-marker-style-twodigits {
  width: 30px;
  height: 30px;
  color: #fff;
  padding-top: 4.7px;
  border-radius: 30px;
  text-align: center;
  font-style: bold;
  font-size: 17px;
  background-color: #000;
  cursor: pointer;
  transition: 0.3s ease;
}

.clustering-marker-style-threedigits {
  width: 33px;
  height: 33px;
  color: #fff;
  padding-top: 7px;
  border-radius: 20px;
  text-align: center;
  font-style: bold;
  font-size: 17px;
  background-color: #000;
  cursor: pointer;
  transition: 0.3s ease;
}

.clustering-marker-style-fourdigits {
  width: 44px;
  height: 44px;
  color: #fff;
  padding-top: 13px;
  border-radius: 20px;
  text-align: center;
  font-style: bold;
  font-size: 17px;
  background-color: #000;
  cursor: pointer;
  transition: 0.3s ease;
}

.clustering-marker-style-fourdigits:hover,
.clustering-marker-style-threedigits:hover,
.clustering-marker-style-twodigits:hover,
.clustering-marker-style-onedigit:hover {
  transform: scale(1.1);
}