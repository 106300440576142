.Journey {
  padding-left: 4px;
  padding-top: 1em;
  min-height: 72px;
  border-bottom: 1px solid #ebebeb;
  background-color: #f8f8f8;
  outline: none;
  position: relative;
}

.journey-padding {
  padding: 0px 10px 10px 10px;
}

.journey-sub {
  padding: 1em 1.2em 1em 1.2em;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

.journey-title-outer-container {
  font-weight: inherit;
  font-family: inherit;
  font-size: 100%;
  display: inline-block;
  width: 100%;
  text-align: center;
}

.journey-title-inner-container {
  line-height: 22px !important;
  //max-height: 44px !important;
  text-overflow: ellipsis !important;
  /*display: -webkit-box !important;
      -webkit-line-clamp: 2 !important;
      -webkit-box-orient: vertical !important;*/
  font-size: 19px !important;
  overflow: hidden !important;
}

.journey-title {
  overflow-wrap: break-word !important;

  font-size: 16px !important;
  // font-weight: 1 !important;
  line-height: 1.375em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
}

.travelPlan-title {
  // overflow-wrap: break-word !important;

  font-size: 18px !important;
  // font-weight: 500 !important;
  line-height: 1.375em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
}

.highlight:before {
  transform: scaleY(1);
}

.highlight {
  background-color: #ffffff !important;
}