.leg-list {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  max-width: 441px;
}

.leg {
  padding: 1.7em 1.2em 1.7em 1.2em;
  min-height: 72px;
  border-bottom: 1px solid #ebebeb;
}

.legActive {
  padding: 1.7em 1.2em 1.7em 1.2em;
  min-height: 72px;
  border-bottom: 1px solid #ebebeb;
  background: #ffffff;
  border-left: 4px solid var(--theme-color);
  //box-shadow: 20px 4px 100px -20px #008489;
  //box-shadow: -300px 0px 50px 10px #008489;
  //-webkit-border-image: -webkit-linear-gradient(left, #008489, #fff) 20 initial;
}

.leg-sub {
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

.leg-detail-outer-container {
  font-weight: inherit;
  font-family: inherit;
  font-size: 100%;
  display: inline-block;
  text-align: right;
  margin-left: 0.5rem;
  padding-bottom: 1rem;
  padding-top: 0.75rem;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  align-self: center;
  display: flex;
}

.leg-detail-inner-container {
  line-height: 22px !important;
  max-height: 44px !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  font-size: 19px !important;
}

.leg-detail {
  overflow-wrap: break-word !important;

  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.33333em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
  min-width: 65px;
}

.leg-title-outer-container {
  font-weight: inherit;
  font-family: inherit;
  font-size: 100%;
  display: inline-block;
  width: 100%;
}

.leg-title-inner-container {
  line-height: 22px !important;
  //max-height: 44px !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  //-webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  font-size: 19px !important;
  //overflow: hidden !important;
}

.leg-title {
  margin: 0px !important;
  word-wrap: break-word !important;

  font-size: 16px !important;
  font-weight: 800 !important;
  line-height: 1.375em !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  //max-height: 4.125em !important;
  color: rgb(72, 72, 72) !important;
}

.leg-subtitle-container {
  line-height: normal !important;

  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  font-weight: inherit;
  font-family: inherit;
  font-size: 100%;
}

.leg-subtitle {
  margin: 0px !important;
  word-wrap: break-word !important;

  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.2857142857142858em !important;
  color: inherit !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-height: 3.8571428571428577em !important;
  -webkit-line-clamp: 3 !important;
  display: inline !important;
  -webkit-box-orient: inline !important;
}

.leg-subtitle-estimated {
  margin: 0px !important;
  word-wrap: break-word !important;

  font-size: 14px !important;
  font-weight: 700 !important;
  color: rgb(158, 0, 0) !important;
  line-height: 1.2857142857142858em !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-height: 3.8571428571428577em !important;
  -webkit-line-clamp: 3 !important;
  display: inline !important;
  -webkit-box-orient: inline !important;
}

.leg-icon-outer-container {
  font-weight: inherit;
  font-family: inherit;
  font-size: 100%;
  display: inline-block;
  text-align: right;
  margin-right: 0.5rem;
  padding-bottom: 1rem;
  padding-top: 0.75rem;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  align-self: center;
  display: flex;
}

.leg-icon-inner-container {
  line-height: 22px !important;
  max-height: 44px !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  font-size: 19px !important;
  overflow: hidden !important;
}

.leg-content {
  margin-top: 2px;
}

.leg-icon {
  overflow-wrap: break-word !important;

  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 1.33333em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
}

.a {
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  font-style: inherit !important;
  line-height: inherit !important;
  text-decoration-line: none !important;
  font-variant: inherit !important;
  padding: 0 !important;
  margin: 0;
  border: none !important;
  background: transparent;
  outline: none;
  cursor: pointer;
}

.show-directions-button {

  font-weight: 600 !important;
  font-size: 14px !important;
  font-style: inherit !important;
  line-height: inherit !important;
  color: rgb(0, 132, 137);
  text-decoration-line: none !important;
  font-variant: inherit !important;
  padding: 0 !important;
  margin: 0;
  border: none !important;
  background: transparent;
  outline: none;
}

.input[type="time"] {
  margin: 0px !important;
  word-wrap: break-word !important;


  line-height: 1.375em !important;
  color: #484848 !important;
}

.Walk,
.Rail,
.Ferry,
.Bus,
.Tram,
.Coach,
.Metro,
.Underground,
.Drive,
.Cycle,
.Destination,
.Plane,
.EvStation,
.ElectricVehicle,
.Park {
  height: 18px;
  width: 18px;
  display: block;
  fill: rgb(72, 72, 72);
}