.Journey {
  padding-left: 4px;
  padding-top: 0.8em;
  min-height: 72px;
  border-bottom: 1px solid #ebebeb;
  background-color: #f8f8f8;
  outline: none;
  position: relative;
}

.Journey:hover {
  cursor: pointer;
}

.Journey:before {
  content: "";
  position: absolute;
  align-self: stretch;
  top: -1px;
  left: 0px;
  border-radius: 0;
  height: 100%;
  padding: 1px;
  border-left: 4px solid var(--theme-color);
  transition: transform 0.15s ease-in-out;
  transform: scaleY(0);
}

.show {
  display: block;
}

.highlight:before {
  transform: scaleY(1);
}

.highlight {
  background-color: #ffffff !important;
}

.hide {
  display: none !important;
}

.journey-sub {
  padding: 1em 1.2em 1em 1.2em;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 10px;
}

.journey-detail-outer-container {
  font-weight: inherit;
  font-family: inherit;
  font-size: 100%;
  text-align: right;
  margin-left: 0.5rem;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  align-self: stretch;
  display: flex;
}

.journey-detail-inner-container {
  line-height: 22px !important;
  max-height: 56px !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
  font-size: 19px !important;
  overflow: hidden !important;
}

.journey-detail {
  overflow-wrap: break-word !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.375em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
}

.journey-detail-subtitle {
  overflow-wrap: break-word !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: normal !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
}

.journey-detail-carbon-unselected {
  font-size: 14px !important;
  font-weight: 500 !important;
  max-height: 34px !important;
}

.journey-detail-carbon {
  font-size: 16px !important;
  font-weight: 500 !important;
  max-height: 34px !important;
  background-color: #ebf2f3;
  border-radius: 13px;
  padding: 0px 5px;
  transition: background-color 0.5s ease;
  margin-top: -10px;
  white-space: nowrap;
}

.journey-detail-carbon:hover {
  background: rgb(0, 185, 191) !important;
  color: white !important;
}

.journey-detail-carbon:hover .carbon-info {
  fill: white !important;
}

.carbon-info {
  margin-bottom: -5px;
  margin-right: 3px;
  margin-left: -3px;
}

.journey-detail-kcalories {
  font-size: 12px !important;
  font-weight: 400 !important;
  max-height: 26px !important;
  flex: 0 0 auto;
  align-self: start;
}

.journey-detail-time {
  font-size: 14px !important;
  max-height: 16px !important;
  font-weight: 400 !important;
}

.journey-title-outer-container-full {
  font-weight: inherit;
  font-family: inherit;
  font-size: 100%;
  display: inline-block;
  width: 100%;
}

.journey-title-inner-container {
  line-height: 22px !important;
  //max-height: 44px !important;
  text-overflow: ellipsis !important;
  /*display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;*/
  font-size: 19px !important;
}

.journey-title {
  overflow-wrap: break-word !important;
  font-size: 16px !important;
  font-weight: 800 !important;
  line-height: 1.375em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
}

.split-journey-title {
  display: inline-flex;
  align-content: space-between;
  justify-content: space-between;
  align-items: baseline;
  width: 101.5%;
}

.journey-subtitle-container {
  line-height: normal !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  font-weight: inherit;
  font-family: inherit;
  font-size: 100%;
}

.split-journey-subtitle-container {
  line-height: normal !important;
  font-weight: inherit;
  font-family: inherit;
  font-size: 100%;
  display: inline-flex;
  align-content: space-between;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  margin-top: 3px;
}

.split-journey-subtitle-container.with-button {
  float: right;
}

.journey-subtitle {
  overflow-wrap: break-word !important;

  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.33333em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
}

.split-journey-subtitle {
  overflow-wrap: break-word !important;

  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.33333em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  width: 70%;
}

.journey-subtitle-thirdparty {
  overflow-wrap: break-word !important;

  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.33333em !important;
  color: #0000ee !important;
  margin: 0px !important;
  text-decoration: underline;
  cursor: pointer;
}

.Walk,
.Rail,
.Ferry,
.Bus,
.Tram,
.Coach,
.Metro,
.Underground,
.Cycle,
.Drive,
.Destination,
.Plane,
.EvStation,
.ElectricVehicle,
.Park,
.Alert {
  height: 16px;
  width: 16px;
  fill: rgb(72, 72, 72);
  margin-right: 0.25rem;
}

.ChevronRight {
  height: 16px;
  width: 16px;
  fill: rgb(118, 118, 118);
  margin-right: 0.25rem;
}

.a {
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  font-style: inherit !important;
  line-height: inherit !important;
  text-decoration-line: none !important;
  font-variant: inherit !important;
  padding: 0 !important;
  margin: 0;
  border: none !important;
  background: transparent;
  outline: none;
}

.journey-subtitle-a {
  overflow-wrap: break-word !important;

  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 1.33333em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
  z-index: 3 !important;
}

.play-pause-button {
  margin-right: 5px;
  float: left;
  padding: 0;
  width: 20px;
  height: 20px;
  position: relative !important;
  text-align: center !important;
  transition-property: background, border-color, color !important;
  transition-duration: 0.2s !important;
  transition-timing-function: ease-out !important;
  cursor: pointer !important;
  display: inline !important;
  box-shadow: none !important;
  text-decoration: none !important;
  border-radius: 12.5px !important;
  border-style: solid !important;
  border-color: transparent !important;
}

.play-pause-icon {
  margin-top: 2px;
  width: 12px;
  height: 12px;
}

@media only screen and (max-width: 600px) {
  .journey-title {
    width: 100%;
    min-width: 270px;
  }
}