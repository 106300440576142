.edit-profile-text-span {
  word-wrap: break-word !important;

  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 1.2857142857142858em !important;
  color: #484848 !important;
  padding-top: 5px;
  margin-left: 20px;
  margin-right: 20px;
}

.icon-information {
  height: 20px;
  width: 35px;
  margin-left: -10px;
  float: left;
}

.modal-subtitle {
  font-size: 16px;
  font-weight: 600;
  margin-left: 22px;
}

.a {
  color: rgb(0, 132, 137) !important;
  text-decoration-line: none !important;
  text-align: left !important;
  font: inherit inherit inherit inherit inherit inherit inherit inherit inherit !important;
}

.form-item {
  margin-bottom: 16px;
}

.form-item-content-container {
  display: block !important;
  width: 100% !important;
}

.form-item-label {
  position: absolute !important;
  display: block !important;
  border: 0px !important;
  margin: -1px !important;
  padding: 0px !important;
  height: 1px !important;
  width: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  overflow: hidden !important;
}

.form-item-control {
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;

  color: rgb(72, 72, 72) !important;
  background-color: rgb(255, 255, 255) !important;
  position: relative !important;
  z-index: 0 !important;
  margin-bottom: 8px !important;
  display: block !important;
  width: 100% !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: rgb(235, 235, 235) !important;
  border-radius: 4px !important;
}

.form-icon-outer-sub {
  float: right !important;
}

.form-icon-inner-sub {
  padding-right: 11px !important;
  visibility: visible;
  height: 46px;
}

.form-icon-outer-container {
  display: table !important;
  position: relative !important;
  height: 100% !important;
}

.form-icon-inner-container {
  display: table-cell !important;
  vertical-align: middle !important;
}

.Email,
.Person,
.Phone {
  height: 1em;
  width: 1em;
  display: block;
  fill: currentcolor;
}

.form-input::placeholder {
  color: #909090;
}

.form-input-container {
  position: relative !important;
  overflow: hidden !important;
}

.form-input {
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;

  color: rgb(72, 72, 72) !important;
  padding-top: 11px !important;
  padding-bottom: 11px !important;
  font-weight: normal !important;
  background-color: transparent !important;
  padding-left: 11px !important;
  padding-right: 11px !important;
  width: 100% !important;
  border-width: 0px !important;
  // border-style: initial !important;
  // border-color: initial !important;
  // border-image: initial !important;
  margin: 0px !important;
}

.form-button-container {
  margin-bottom: 16px;
}

.form-button {
  display: inline-block !important;
  position: relative !important;
  text-align: center !important;
  transition-property: background, border-color, color !important;
  transition-duration: 0.2s !important;
  transition-timing-function: ease-out !important;
  cursor: pointer !important;
  width: 100% !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;

  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-weight: 800 !important;
  padding-left: 22px !important;
  padding-right: 22px !important;
  min-width: 71.1935px !important;
  box-shadow: none !important;
  color: rgb(255, 255, 255) !important;
  margin: 0px !important;
  text-decoration: none !important;
  border-radius: 4px !important;
  border-width: 2px !important;
  border-style: solid !important;
  background: rgb(0, 132, 137) !important;
  border-color: transparent !important;
}

.form-button-span {
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;

  text-transform: undefined !important;
  color: inherit !important;
  padding-top: undefined !important;
  padding-bottom: undefined !important;
}

.NominatedAssistance {
  position: relative;
  width: 100%;
  height: 100% !important;
  max-width: 441px;
  padding: 6px 16px 36px 16px !important;
  display: block !important;
  overflow-y: auto !important;
  background: #ffffff !important;
}

.copy-button-container {
  font-weight: 600 !important;
  padding-left: 5px !important;
  margin-right: -5px;
}

.update-profile-button {
  position: relative !important;
  text-align: center !important;
  transition-property: background, border-color, color !important;
  transition-duration: 0.2s !important;
  transition-timing-function: ease-out !important;
  cursor: pointer !important;
  //display: block !important;
  width: 97.5% !important;
  font-size: 14px !important;
  letter-spacing: normal !important;

  padding-top: 8px !important;
  padding-bottom: 8px !important;
  font-weight: 600 !important;
  padding-left: 22px !important;
  padding-right: 22px !important;
  min-width: 71.1935px !important;
  box-shadow: none !important;
  color: rgb(255, 255, 255);
  //margin: 0px 10px 0px 10px !important;
  text-decoration: none !important;
  border-radius: 4px 4px 4px 4px !important;
  border-right-width: 1px !important;
  border-left-width: 1px !important;
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
  border-style: solid !important;
  background: #ffffff;
  border-color: lightgrey !important;
}

.update-profile-button-span {
  font-size: var(--font-button-font-size, 16px) !important;
  line-height: var(--font-button-line-height, 24px) !important;
  letter-spacing: var(--font-button-letter-spacing, normal) !important;

  text-transform: var(--font-button-text-transform, undefined) !important;
  padding-top: var(--font-button-padding-top, undefined) !important;
  padding-bottom: var(--font-button-padding-bottom, undefined) !important;
  color: rgb(0, 132, 137) !important;
}

.options-subtitle-container {
  margin-bottom: 16px;
}

.options-subtitle {
  overflow-wrap: break-word !important;

  font-size: 17px !important;
  font-weight: 600 !important;
  line-height: 1.44444em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
}

.option-item {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border-bottom: 0px !important;
}

.option-item-label-outer-container {
  display: table !important;
  width: 100% !important;
  border-spacing: 0px !important;
}

.option-item-label-inner-container {
  display: table-cell !important;
  width: 100% !important;
  vertical-align: middle !important;
}

.option-item-label {
  cursor: pointer !important;
  display: block !important;
  background: transparent !important;
  border-width: 0px !important;
  border-style: initial !important;
  border-color: initial !important;
  border-image: initial !important;
  padding: 0px !important;
}

.option-item-title-outer-container {
  display: table !important;
  width: 100% !important;
  border-spacing: 0px !important;
}

.option-item-title-inner-container {
  display: table-cell !important;
  width: 100% !important;
  vertical-align: top !important;
}

.option-item-title {
  font-weight: normal !important;
  cursor: pointer !important;
  padding-left: 0px !important;
  padding-right: 8px !important;
}

.option-item-title-span {
  font-size: 16px !important;
  line-height: 26px !important;
  letter-spacing: normal !important;

  color: rgb(72, 72, 72) !important;
  display: inline-block !important;
  vertical-align: top !important;
}

.option-item-control-outer-container {
  display: table-cell !important;
  vertical-align: top !important;
}

.option-item-control-inner-container {
  position: relative !important;
  top: 2px !important;
}

.option-item-control-span {
  display: inline-block !important;
  cursor: pointer !important;
  padding: 0px !important;
}

.option-item-control-input {
  position: absolute !important;
  width: 0px !important;
  opacity: 0 !important;
}

.option-item-control-icon-inactive {
  height: 28px !important;
  width: 28px !important;
  display: inline-block !important;
  text-align: center !important;
  background: rgb(255, 255, 255) !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: rgb(176, 176, 176) !important;
  border-radius: 8px !important;
  overflow: hidden !important;
}

.option-item-control-icon-active {
  height: 32px !important;
  width: 32px !important;
  display: inline-block !important;
  text-align: center !important;
  color: rgb(255, 255, 255) !important;
  background: rgb(0, 132, 137) !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-radius: 8px !important;
  overflow: hidden !important;
  border-color: rgb(0, 132, 137) !important;
}

.option-item-control-icon-inner-span {
  position: relative !important;
  display: inline-block !important;
  left: -1px !important;
  font-size: 32px !important;
  padding-top: 2px !important;
  margin: -8px !important;
}

.Check {
  height: 20px;
  width: 20px;
  display: block;
  overflow: visible;
  fill: currentcolor;
}

.options-divider-container {
  margin-top: 16px;
  margin-bottom: 16px;
}

.options-divider {
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
  border-bottom-color: rgb(235, 235, 235) !important;
}

.error {
  font-size: 14px !important;
  line-height: 18px !important;
  letter-spacing: normal !important;
  font-weight: 600 !important;
  color: rgb(217, 57, 0) !important;
}

.confirm-button-container {
  vertical-align: middle !important;
}

.confirm-button {
  position: relative !important;
  text-align: center !important;
  transition-property: background, border-color, color !important;
  transition-duration: 0.2s !important;
  transition-timing-function: ease-out !important;
  cursor: pointer !important;
  display: block !important;
  width: 100% !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;

  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-weight: 800 !important;
  padding-left: 22px !important;
  padding-right: 22px !important;
  min-width: 71.1935px !important;
  box-shadow: none !important;
  color: rgb(255, 255, 255) !important;
  margin: 0px;
  text-decoration: none !important;
  border-radius: 4px !important;
  border-width: 2px !important;
  border-style: solid !important;
  background: rgb(0, 132, 137) !important;
  border-color: transparent !important;
  margin-bottom: 20px;
}

.confirm-button:hover {
  background: #00b9bf !important;
}

.confirm-button-span {
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;

  color: inherit !important;
}

.pending-button {
  position: relative !important;
  text-align: center !important;
  transition-property: background, border-color, color !important;
  transition-duration: 0.2s !important;
  transition-timing-function: ease-out !important;
  cursor: pointer !important;
  display: block !important;
  width: 100% !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;

  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-weight: 800 !important;
  padding-left: 22px !important;
  padding-right: 22px !important;
  min-width: 71.1935px !important;
  box-shadow: none !important;
  color: rgb(255, 255, 255) !important;
  margin: 0px;
  text-decoration: none !important;
  border-radius: 4px !important;
  border-width: 2px !important;
  border-style: solid !important;
  background: #e0e0e0 !important;
  border-color: transparent !important;
  margin-bottom: 20px;
}

.pending-button-span {
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;

  color: inherit !important;
}

@supports (--custom: properties) {
  .confirm-button {
    font-size: var(--font-button-font-size, 16px) !important;
    line-height: var(--font-button-line-height, 24px) !important;
    letter-spacing: var(--font-button-letter-spacing, normal) !important;

    text-transform: var(--font-button-text-transform, undefined) !important;
    padding-top: var(--spacing-button-vertical, 10px) !important;
    padding-bottom: var(--spacing-button-vertical, 10px) !important;
    font-weight: var(--font-bold-font-weight, 800) !important;
    padding-left: var(--spacing-button-horizontal, 22px) !important;
    padding-right: var(--spacing-button-horizontal, 22px) !important;
    box-shadow: var(--shadow-button-level0-box-shadow, none) !important;
    color: #ffffff !important;
  }

  .confirm-button-span {
    font-size: var(--font-button-font-size, 16px) !important;
    line-height: var(--font-button-line-height, 24px) !important;
    letter-spacing: var(--font-button-letter-spacing, normal) !important;

    text-transform: var(--font-button-text-transform, undefined) !important;
    padding-top: var(--font-button-padding-top, undefined) !important;
    padding-bottom: var(--font-button-padding-bottom, undefined) !important;
  }

  .pending-button {
    font-size: var(--font-button-font-size, 16px) !important;
    line-height: var(--font-button-line-height, 24px) !important;
    letter-spacing: var(--font-button-letter-spacing, normal) !important;

    text-transform: var(--font-button-text-transform, undefined) !important;
    padding-top: var(--spacing-button-vertical, 10px) !important;
    padding-bottom: var(--spacing-button-vertical, 10px) !important;
    font-weight: var(--font-bold-font-weight, 800) !important;
    padding-left: var(--spacing-button-horizontal, 22px) !important;
    padding-right: var(--spacing-button-horizontal, 22px) !important;
    box-shadow: var(--shadow-button-level0-box-shadow, none) !important;
    color: #484848 !important;
  }

  .pending-button-span {
    font-size: var(--font-button-font-size, 16px) !important;
    line-height: var(--font-button-line-height, 24px) !important;
    letter-spacing: var(--font-button-letter-spacing, normal) !important;

    text-transform: var(--font-button-text-transform, undefined) !important;
    padding-top: var(--font-button-padding-top, undefined) !important;
    padding-bottom: var(--font-button-padding-bottom, undefined) !important;
  }
}