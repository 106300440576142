.form-input {
  -webkit-appearance: none;
  -webkit-user-modify: read-write-plaintext-only;
  -moz-appearance: none;
  appearance: none;
  border: 0px transparent;
  background: transparent;
  outline: none;
  font-family: inherit;
  font-size: 18px !important;
  letter-spacing: normal !important;
  font-weight: 600 !important;
  text-overflow: ellipsis !important;
  color: rgb(72, 72, 72) !important;
  outline-color: initial;
  outline-style: none;
  outline-width: initial;
  padding-right: 11px;
  padding-left: 2px;
  //margin-top: 2px;
  width: 250px;
  touch-action: none;
}

/* Smartphones (portrait) */
@media (hover: none) and (pointer: coarse) and (orientation: portrait) {
  .form-input {
    margin-top: 6px !important;
  }
}