.Modal {
  z-index: 2000;
  position: fixed !important;
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  transform: translate3d(0px, 0px, 0px) !important;
  pointer-events: none !important;
  // overflow-y: hidden !important;
  background: none !important;
  max-width: 441px; //441px !important;
  margin-bottom: 42px;
}

.content {
  z-index: 2001;
  pointer-events: auto !important;
  background-color: rgb(255, 255, 255) !important;
  box-shadow: rgba(22, 22, 22, 0.2) 0px 0px 2px 0px !important;
  height: 100% !important;
  width: 100% !important;
  // overflow-y: hidden !important;
  position: relative !important;
  will-change: transform !important;
  border-top: 1px solid rgb(235, 235, 235) !important;
}

.header {
  height: 53px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  display: table !important;
  vertical-align: top !important;
  width: 100% !important;
  border-bottom: 1px solid rgb(235, 235, 235) !important;
}

.button-container {
  display: table-cell !important;
  text-align: left !important;
  vertical-align: middle !important;
  width: 5% !important;
}

.button {
  padding: 20px;
  margin: -20px;
  cursor: pointer !important;
  background-color: transparent !important;
  color: buttontext !important;
  border: 0px !important;
  display: block !important;
}

.title-container {
  display: table-cell !important;
  text-align: center !important;
  vertical-align: middle !important;
  width: 95% !important;
  padding-right: 40px;
}

.title {
  margin: 0px !important;
  padding-top: 2px;
  padding-left: 13px;
  word-wrap: break-word !important;

  line-height: 24px;
  letter-spacing: normal;
  font-weight: 700;
  font-size: 22px;
}

.action-container {
  display: table-cell !important;
  text-align: right !important;
  vertical-align: middle !important;
  width: 30% !important;
}

.action {
  overflow-wrap: break-word !important;

  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 1.28571em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
}

.main {
  // overflow-y: auto !important;
  overflow-x: hidden !important;
  width: 100% !important;
  position: absolute !important;
  top: 58px !important;
  bottom: 0px !important;
  padding: 0px 0px 5px 0px;
}

.Close {
  height: 18px;
  width: 18px;
  display: block;
  fill: rgb(72, 72, 72);
}

.filter-button-container {
  //vertical-align: middle !important;
  //margin-top: 10px;
  width: 90%;
  display: block;
  margin: 1em 1.2em 1em 1.2em !important;
}

.assistance-sub {
  padding: 1em 1.2em 1em 1.2em;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  border-top: 2px solid #ebebeb;
  border-bottom: 2px solid #ebebeb;
}

.assistance-title-outer-container {
  font-weight: inherit;
  font-family: inherit;
  font-size: 100%;
  display: inline-block;
  width: 100%;
}

.assistance-title-inner-container {
  line-height: 22px !important;
  //max-height: 44px !important;
  text-overflow: ellipsis !important;
  /*display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;*/
  font-size: 19px !important;
  overflow: hidden !important;
}

.assistance-title {
  overflow-wrap: break-word !important;

  font-size: 12px !important;
  font-weight: 800 !important;
  line-height: 1.375em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
}

.assistance-title-copy {
  overflow-wrap: break-word !important;

  font-size: 18px !important;
  font-weight: 800 !important;
  line-height: 1.375em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
}

.assistance-details {
  overflow-wrap: break-word !important;

  font-size: 14px !important;
  font-weight: 600;
  line-height: 1.375em !important;
  color: rgb(72, 72, 72) !important;
  margin: 10px 0px 10px 0px !important;
}

.assistance-information {
  font-weight: 200;
  display: inline;
}

.assistance-button-span {
  font-size: var(--font-button-font-size, 16px) !important;
  line-height: var(--font-button-line-height, 24px) !important;
  letter-spacing: var(--font-button-letter-spacing, normal) !important;

  text-transform: var(--font-button-text-transform, undefined) !important;
  padding-top: var(--font-button-padding-top, undefined) !important;
  padding-bottom: var(--font-button-padding-bottom, undefined) !important;
}

.assistance-button {
  position: relative !important;
  text-align: center !important;
  transition-property: background, border-color, color !important;
  transition-duration: 0.2s !important;
  transition-timing-function: ease-out !important;
  cursor: pointer !important;
  display: block !important;
  width: 100% !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;

  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-weight: 800 !important;
  padding-left: 22px !important;
  padding-right: 22px !important;
  min-width: 71.1935px !important;
  box-shadow: none !important;
  color: rgb(255, 255, 255);
  margin: 0px !important;
  text-decoration: none !important;
  border-radius: 4px !important;
  border-width: 2px !important;
  border-style: solid !important;
  background: rgb(0, 132, 137) !important;
  border-color: transparent !important;
}

.assistance-button:hover {
  background: #00b9bf !important;
}

.pending-button-span {
  font-size: var(--font-button-font-size, 16px) !important;
  line-height: var(--font-button-line-height, 24px) !important;
  letter-spacing: var(--font-button-letter-spacing, normal) !important;

  text-transform: var(--font-button-text-transform, undefined) !important;
  padding-top: var(--font-button-padding-top, undefined) !important;
  padding-bottom: var(--font-button-padding-bottom, undefined) !important;
}

.pending-button {
  position: relative !important;
  text-align: center !important;
  transition-property: background, border-color, color !important;
  transition-duration: 0.2s !important;
  transition-timing-function: ease-out !important;
  cursor: pointer !important;
  display: block !important;
  width: 100% !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;

  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-weight: 800 !important;
  padding-left: 22px !important;
  padding-right: 22px !important;
  min-width: 71.1935px !important;
  box-shadow: none !important;
  color: #484848 !important;
  margin: 0px !important;
  text-decoration: none !important;
  border-radius: 4px !important;
  border-width: 2px !important;
  border-style: solid !important;
  background: #e0e0e0 !important;
  border-color: transparent !important;
}

.api-button-span {
  font-size: var(--font-button-font-size, 16px) !important;
  line-height: var(--font-button-line-height, 24px) !important;
  letter-spacing: var(--font-button-letter-spacing, normal) !important;

  text-transform: var(--font-button-text-transform, undefined) !important;
  padding-top: var(--font-button-padding-top, undefined) !important;
  padding-bottom: var(--font-button-padding-bottom, undefined) !important;
}

.api-button {
  position: relative !important;
  text-align: center !important;
  transition-property: background, border-color, color !important;
  transition-duration: 0.2s !important;
  transition-timing-function: ease-out !important;
  cursor: pointer !important;
  display: block !important;
  width: 100% !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;

  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-weight: 800 !important;
  padding-left: 22px !important;
  padding-right: 22px !important;
  min-width: 71.1935px !important;
  box-shadow: none !important;
  color: #484848 !important;
  margin: 0px !important;
  text-decoration: none !important;
  border-radius: 4px !important;
  border-width: 2px !important;
  border-style: solid !important;
  background: #e0e0e0 !important;
  border-color: transparent !important;
}