.marker-modal-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
  justify-content: space-between;
}

.numeric-buttons-container {
  position: relative;
  display: table;
  width: 100%;
}

.numeric-button-container {
  height: 100px;
  background: #ffffff;
  border: 1px solid rgb(235, 235, 235);
  border-radius: 4px;
  text-align: center;
  padding: 10px;
  min-width: 150px;
  cursor: pointer;
  flex: 0 1 calc(50% - 10px);
  box-sizing: border-box;
}

@media (min-width: 390px) {
  .numeric-button-container {
    min-width: 160px;
  }
}

@media (min-width: 410px) {
  .numeric-button-container {
    min-width: 170px;
  }
}

@media (min-width: 425px) {
  .numeric-button-container {
    min-width: 180px;
  }
}

@media (min-width: 500px) {
  .numeric-button-container {
    min-width: 185px;
  }
}

.numeric-button-container-minus {
  height: 35px;
  width: 35px;
  float: left;
  margin-top: 10px;
}

.numeric-button-container-plus {
  height: 35px;
  width: 35px;
  float: right;
  margin-top: 10px;
}

.step-free-container-icon {
  margin-top: 12px;
  margin-bottom: 30px;
  position: relative;
}

.numeric-button-container-icon {
  height: 40px;
  width: 40px;
}

.numeric-button-container-icon-box {
  margin-top: 10px;
  position: relative;
}

.numeric-step-free-button-text-box {
  position: relative;
  bottom: 10px;
}

.numeric-button-text-box {
  position: relative;
  bottom: 10px;
}

.numeric-button-text {
  line-height: initial;
  margin-block-end: 0em;
}

.numeric-button-bottom-text {
  line-height: initial;
  margin-block-start: 0em;
}

.modal-subtitle {
  font-size: 16px;
  font-weight: 600;
}

@media only screen and (max-width: 350px) {
  .numeric-button-container {
    margin-left: 25%;
    float: none;
  }

  .numeric-button-container-left {
    margin-left: 25%;
    float: none;
  }
}