.error-frame {
  position: absolute;
  top: 165px;
  width: 441px;
}

.error-outer-box {
  max-width: 441px;
  position: absolute;
  width: 100% !important;
  top: calc(165px + var(--header-top-padding) + var(--journey-locations-height));
  padding-left: 4px;
  padding-top: 1em;
  min-height: 72px;
  border-bottom: 1px solid #ebebeb;
  background-color: #ffffff;
  outline: none;
  z-index: 1 !important;
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) !important;
  -moz-transition: transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) !important;
  transition: -ms-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    -webkit-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) !important;
  will-change: transform !important;
  overflow-y: hidden !important;
}

.error-outer-box:hover {
  cursor: pointer;
}

.error-outer-box:before {
  content: "";
  position: absolute;
  align-self: stretch;
  top: -1px;
  left: 0px;
  border-radius: 0;
  height: 100%;
  padding: 1px;
  border-left: 4px solid rgb(167, 5, 5);
  transition: transform 0.15s ease-in-out;
}

.error-inner-box {
  padding: 1em 1.2em 1em 1.2em;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  margin-bottom: 10px;
}

.error-content {
  overflow-wrap: break-word !important;

  font-size: 16px !important;
  font-weight: 800 !important;
  line-height: 1.375em !important;
  color: #484848 !important;
  margin: 0 !important;
}

.error-subtitle-container {
  line-height: normal !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  font-weight: inherit;
  font-family: inherit;
  font-size: 100%;
}

.error-subtitle {
  overflow-wrap: break-word !important;

  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 1.33333em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
}

.Warning {
  height: 20px;
  width: 20px;
  fill: rgb(72, 72, 72);
  margin-right: 0.25rem;
  margin-top: 2px;
}

.a {
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  font-style: inherit !important;
  line-height: inherit !important;
  color: rgb(0, 132, 137) !important;
  text-decoration-line: none !important;
  font-variant: inherit !important;
  padding: 0 !important;
  margin: 0;
  border: none !important;
  background: transparent;
  outline: none;
}