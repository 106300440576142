.JourneyCollection {
  position: relative !important;
  z-index: 0 !important;
  background: #f8f8f8 !important;
  height: 100% !important;
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) !important;
  -moz-transition: transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) !important;
  transition: -ms-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    -webkit-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) !important;
  will-change: transform !important;
  width: 100% !important;
  // overflow-y: auto !important;
  max-width: 441px;
  // margin-bottom: 40px !important;
  border-top: 2px solid #ebebeb;
}

.journey-list {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  /*max-width: 441px;*/
  //width: 441px;
}

.api-button-span {
  font-size: var(--font-button-font-size, 16px) !important;
  line-height: var(--font-button-line-height, 24px) !important;
  letter-spacing: var(--font-button-letter-spacing, normal) !important;

  text-transform: var(--font-button-text-transform, undefined) !important;
  padding-top: var(--font-button-padding-top, undefined) !important;
  padding-bottom: var(--font-button-padding-bottom, undefined) !important;
}

.api-button {
  position: relative !important;
  text-align: center !important;
  transition-property: background, border-color, color !important;
  transition-duration: 0.2s !important;
  transition-timing-function: ease-out !important;
  cursor: pointer !important;
  display: block !important;
  width: 100% !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;

  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-weight: 800 !important;
  padding-left: 22px !important;
  padding-right: 22px !important;
  min-width: 71.1935px !important;
  box-shadow: none !important;
  color: #e0e0e0 !important;
  margin: 0px !important;
  text-decoration: none !important;
  border-radius: 4px !important;
  border-width: 2px !important;
  border-style: solid !important;
  background: #e0e0e0 !important;
  border-color: transparent !important;
}

.copy-button-container {
  vertical-align: middle !important;
  padding-top: var(--spacing-button-vertical, 10px) !important;
  padding-bottom: var(--spacing-button-vertical, 10px) !important;
  font-weight: var(--font-bold-font-weight, 800) !important;
  padding-left: var(--spacing-button-horizontal, 22px) !important;
  padding-right: var(--spacing-button-horizontal, 20px) !important;
}

@supports (--custom: properties) {
  .api-button {
    font-size: var(--font-button-font-size, 16px) !important;
    line-height: var(--font-button-line-height, 24px) !important;
    letter-spacing: var(--font-button-letter-spacing, normal) !important;

    text-transform: var(--font-button-text-transform, undefined) !important;
    box-shadow: var(--shadow-button-level0-box-shadow, none) !important;
    color: #484848 !important;
  }

  .api-button-span {
    font-size: var(--font-button-font-size, 16px) !important;
    line-height: var(--font-button-line-height, 24px) !important;
    letter-spacing: var(--font-button-letter-spacing, normal) !important;

    text-transform: var(--font-button-text-transform, undefined) !important;
    padding-top: var(--font-button-padding-top, undefined) !important;
    padding-bottom: var(--font-button-padding-bottom, undefined) !important;
  }
}

@media only screen and (max-width: 600px) {
  .JourneyCollection {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    background: #ffffff !important;
    border-radius: 0px !important;
    max-width: 1080px !important;
    width: auto;
    box-shadow: none;
    position: relative;
    height: 33.333333vh !important;
    overflow-y: visible !important;
    //min-height: calc(100vh - 430px) !important;
    //bottom: 40px !important;
    z-index: 2 !important;
    //////max-height: inherit !important;
    //height: inherit !important;
    // box-sizing: border-box !important;
    // margin-bottom: 40px !important;
  }

  .journey-list {
    width: 100%;
    overflow: visible !important;
    //padding-bottom: 40px;
  }

  .journey-list:last-child {
    padding-bottom: 40px !important;
  }

  .noPlans-title {
    overflow-wrap: break-word !important;
    font-size: 18px !important;
    font-weight: 800 !important;
    line-height: 1.375em !important;
    color: rgb(72, 72, 72) !important;
    margin: 0px !important;
  }
}