.pop-up-inner-span {
  overflow: hidden;
}

.header {
  text-align: center;
  font-weight: bold;
  font-size: medium;
}

*:focus {
  outline: none;
}

iframe {
  border: none;
}

.mapboxgl-popup {
  max-width: 240px;
}
