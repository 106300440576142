.Journey {
  padding-left: 4px;
  padding-top: 1em;
  min-height: 72px;
  border-bottom: 1px solid #ebebeb;
  background-color: #f8f8f8;
  outline: none;
  position: relative;
}

.Journey:hover {
  cursor: pointer;
}

.show {
  display: block;
}

.highlight:before {
  transform: scaleY(1);
}

.highlight {
  background-color: #ffffff !important;
}

.hide {
  display: none !important;
}

.journey-sub {
  padding: 1em 1.2em 1em 1.2em;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  //   margin-bottom: 10px;
}

.journey-detail-outer-container {
  font-weight: inherit;
  font-family: inherit;
  font-size: 100%;
  display: inline-block;
  text-align: right;
  margin-left: 0.5rem;
  padding-bottom: 1rem;
  padding-top: 0.75rem;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  align-self: center;
  display: flex;
}

.journey-detail-inner-container {
  line-height: 22px !important;
  max-height: 44px !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  font-size: 19px !important;
  overflow: hidden !important;
}

.journey-detail {
  overflow-wrap: break-word !important;

  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.33333em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
}

.journey-title-outer-container {
  font-weight: inherit;
  font-family: inherit;
  font-size: 100%;
  display: inline-block;
  width: 80%;
}

.journey-title-inner-container {
  line-height: 22px !important;
  //max-height: 44px !important;
  text-overflow: ellipsis !important;
  /*display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;*/
  font-size: 19px !important;
  overflow: hidden !important;
}

.journey-title {
  overflow-wrap: break-word !important;

  font-size: 16px !important;
  font-weight: 800 !important;
  line-height: 1.375em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
}

.travelPlan-title {
  overflow-wrap: break-word !important;

  font-size: 18px !important;
  font-weight: 800 !important;
  line-height: 1.375em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
}

.travelPlan-locations {
  overflow-wrap: break-word !important;

  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 1.375em !important;
  color: rgb(72, 72, 72) !important;
  margin: 10px 0px 10px 0px !important;
}

.journey-subtitle-container {
  line-height: normal !important;
  // max-height: 22px !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  font-weight: inherit;
  font-family: inherit;
  font-size: 100%;
}

.journey-subtitle-margin {
  margin-top: 10px;
}

.journey-subtitle {
  overflow-wrap: break-word !important;

  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.33333em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
}

.Walk,
.Rail,
.Ferry,
.Bus,
.Tram,
.Coach,
.Metro,
.Underground,
.Cycle,
.Drive,
.EvStation,
.ElectricVehicle,
.Park {
  height: 16px;
  width: 16px;
  fill: rgb(72, 72, 72);
  margin-right: 0.25rem;
}

.ChevronRight {
  height: 16px;
  width: 16px;
  fill: rgb(118, 118, 118);
  margin-right: 0.25rem;
}

.a {
  font-size: inherit !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  font-style: inherit !important;
  line-height: inherit !important;
  color: rgb(0, 132, 137) !important;
  text-decoration-line: none !important;
  font-variant: inherit !important;
  padding: 0 !important;
  margin: 0;
  border: none !important;
  background: transparent;
  outline: none;
}

.journey-subtitle-a {
  overflow-wrap: break-word !important;

  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 1.33333em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
  z-index: 3 !important;
}

.copy-button-container {
  vertical-align: middle !important;
  margin-top: 10px;
}

.confirm-button {
  position: relative !important;
  text-align: center !important;
  transition-property: background, border-color, color !important;
  transition-duration: 0.2s !important;
  transition-timing-function: ease-out !important;
  cursor: pointer !important;
  display: inline-block !important;
  width: 45% !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;

  padding-top: var(--spacing-button-vertical, 10px) !important;
  padding-bottom: var(--spacing-button-vertical, 10px) !important;
  font-weight: var(--font-bold-font-weight, 800) !important;
  padding-left: var(--spacing-button-horizontal, 22px) !important;
  padding-right: var(--spacing-button-horizontal, 22px) !important;
  min-width: 71.1935px !important;
  box-shadow: none !important;
  color: rgb(255, 255, 255) !important;
  margin: 0px 10px 0px 10px !important;
  text-decoration: none !important;
  border-radius: 4px !important;
  border-width: 2px !important;
  border-style: solid !important;
  background: rgb(0, 132, 137) !important;
  border-color: transparent !important;
}

.confirm-button-no-assistance {
  position: relative !important;
  text-align: center !important;
  transition-property: background, border-color, color !important;
  transition-duration: 0.2s !important;
  transition-timing-function: ease-out !important;
  cursor: pointer !important;
  display: inline-block !important;
  width: 95% !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;

  padding-top: var(--spacing-button-vertical, 10px) !important;
  padding-bottom: var(--spacing-button-vertical, 10px) !important;
  font-weight: var(--font-bold-font-weight, 800) !important;
  padding-left: var(--spacing-button-horizontal, 22px) !important;
  padding-right: var(--spacing-button-horizontal, 22px) !important;
  min-width: 71.1935px !important;
  box-shadow: none !important;
  color: rgb(255, 255, 255) !important;
  margin: 0px 10px 0px 10px !important;
  text-decoration: none !important;
  border-radius: 4px !important;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: transparent !important;
}

.confirm-button-span {
  font-size: var(--font-button-font-size, 16px) !important;
  line-height: var(--font-button-line-height, 24px) !important;
  letter-spacing: var(--font-button-letter-spacing, normal) !important;

  text-transform: var(--font-button-text-transform, undefined) !important;
  padding-top: var(--font-button-padding-top, undefined) !important;
  padding-bottom: var(--font-button-padding-bottom, undefined) !important;
}

.confirm-assistance-button {
  position: relative !important;
  text-align: center !important;
  transition-property: background, border-color, color !important;
  transition-duration: 0.2s !important;
  transition-timing-function: ease-out !important;
  cursor: pointer !important;
  display: inline-block !important;
  width: 45% !important;
  letter-spacing: normal !important;
  background: rgb(0, 132, 137) !important;
  border-color: transparent !important;
  border-radius: 4px !important;
  border-width: 2px !important;
  border-style: solid !important;
  // margin: 0px 10px 0px 10px !important;
  line-height: 24px !important;
  font-size: 16px !important;
  padding-top: var(--spacing-button-vertical, 10px) !important;
  padding-bottom: var(--spacing-button-vertical, 10px) !important;
  font-weight: var(--font-bold-font-weight, 800) !important;
  box-shadow: none !important;
  color: #484848 !important;
  background: #e0e0e0 !important;
}

.confirm-assistance-button-span {
  font-size: var(--font-button-font-size, 14px) !important;
  line-height: var(--font-button-line-height, 24px) !important;
  letter-spacing: var(--font-button-letter-spacing, normal) !important;

  text-transform: var(--font-button-text-transform, undefined) !important;
  padding-top: var(--font-button-padding-top, undefined) !important;
  padding-bottom: var(--font-button-padding-bottom, undefined) !important;
}

.journey-padding {
  padding: 0px 10px 10px 10px;
}

@supports (--custom: properties) {
  .confirm-button {
    font-size: var(--font-button-font-size, 16px) !important;
    line-height: var(--font-button-line-height, 24px) !important;
    letter-spacing: var(--font-button-letter-spacing, normal) !important;

    text-transform: var(--font-button-text-transform, undefined) !important;
    padding-top: var(--spacing-button-vertical, 10px) !important;
    padding-bottom: var(--spacing-button-vertical, 10px) !important;
    font-weight: var(--font-bold-font-weight, 800) !important;
    padding-left: var(--spacing-button-horizontal, 22px) !important;
    padding-right: var(--spacing-button-horizontal, 22px) !important;
    box-shadow: var(--shadow-button-level0-box-shadow, none) !important;
    color: #ffffff !important;
  }

  .confirm-button-span {
    font-size: var(--font-button-font-size, 16px) !important;
    line-height: var(--font-button-line-height, 24px) !important;
    letter-spacing: var(--font-button-letter-spacing, normal) !important;

    text-transform: var(--font-button-text-transform, undefined) !important;
    padding-top: var(--font-button-padding-top, undefined) !important;
    padding-bottom: var(--font-button-padding-bottom, undefined) !important;
  }
}

@media only screen and (max-width: 600px) {
  .journey-title {
    width: 100%;
    min-width: 270px;
  }
}