.clustering-marker-style-onedigit {
  width: 25px;
  height: 25px;
  color: #fff;
  padding-top: 2.5px;
  border-radius: 20px;
  text-align: center;
  font-style: bold;
  font-size: 17px;
  background-color: rgb(180, 0, 0);
}

.clustering-marker-style-twodigits {
  width: 30px;
  height: 30px;
  color: #fff;
  padding-top: 5.5px;
  padding-right: 1px;
  border-radius: 30px;
  text-align: center;
  font-style: bold;
  font-size: 17px;
  background-color: rgb(180, 0, 0);
}

.clustering-marker-style-threedigits {
  width: 33px;
  height: 33px;
  color: #fff;
  padding-top: 7px;
  border-radius: 20px;
  text-align: center;
  font-style: bold;
  font-size: 17px;
  background-color: rgb(180, 0, 0);
}

.clustering-marker-style-fourdigits {
  width: 44px;
  height: 44px;
  color: #fff;
  padding-top: 13px;
  border-radius: 20px;
  text-align: center;
  font-style: bold;
  font-size: 17px;
  background-color: rgb(180, 0, 0);
}
