.AddNotification {
  position: relative;
  width: 100%;
  height: 100% !important;
  max-width: 441px;
  padding: 0px 16px 36px 16px !important;
  display: block !important;
  overflow-y: auto !important;
  background: #ffffff !important;
  margin-top: calc(var(--header-top-padding))
}

.sub {
  -webkit-box-direction: normal !important;
  -webkit-box-orient: horizontal !important;
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-direction: row !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.content {
  min-width: 0px !important;
  width: 100% !important;
  margin: 0 !important;
}

.collection-container {
  -webkit-box-align: stretch !important;
  -webkit-box-direction: normal !important;
  -webkit-box-orient: horizontal !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: stretch !important;
  margin-left: -8px !important;
  margin-right: -8px !important;
}

.item-outer-container {
  -webkit-box-direction: normal !important;
  -webkit-box-orient: vertical !important;
  display: flex !important;
  flex-direction: column !important;
  margin-left: 8px !important;
  margin-right: 8px !important;
  flex: 1 1 0% !important;
}

.item-inner-container {
  margin-right: 24px !important;
  flex: 1 1 auto !important;
}

.subtitle-container {
  margin-bottom: 16px;
}

.h3 {
  color: inherit !important;
  font-size: 1em !important;
  font-weight: inherit !important;
  line-height: inherit !important;
  margin: 0px !important;
  padding: 0px !important;
}

.subtitle {
  margin: 0px !important;
  word-wrap: break-word !important;

  font-size: 17px !important;
  font-weight: 600 !important;
  line-height: 1.375em !important;
  color: #484848 !important;
}

.description-outer-container {
  margin-bottom: 24px;
}

.description-inner-container {
  white-space: pre-line !important;
  -ms-word-wrap: break-word !important;
  word-wrap: break-word !important;
}

.description {
  margin: 0px !important;
  word-wrap: break-word !important;

  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 1.2857142857142858em !important;
  color: #484848 !important;
}

.break-container {
  margin-top: 0px;
  margin-bottom: 24px;
}

.break {
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
  border-bottom-color: #ebebeb !important;
}

@supports (--custom: properties) {
  .break {
    border-bottom-width: var(--border-rule-border-width, 1px) !important;
    border-bottom-color: var(--color-divider, #ebebeb) !important;
  }

  .a {
    color: #008489 !important;
    font-family: var(--font-font_family,
        Circular,
        -apple-system,
        BlinkMacSystemFont,
        Roboto,
        Helvetica Neue,
        sans-serif) !important;
    text-decoration-line: var(--font-link-text-decoration-line,
        none) !important;
  }

  .form-item-control {
    font-size: var(--font-form-element-font-size, 16px) !important;
    line-height: var(--font-form-element-line-height, 24px) !important;
    letter-spacing: var(--font-form-element-letter-spacing, normal) !important;
    text-transform: var(--font-form-element-text-transform,
        undefined) !important;
    color: #484848 !important;
    padding-top: var(--font-form-element-padding-top, undefined) !important;
    padding-bottom: var(--font-form-element-padding-bottom,
        undefined) !important;
    background-color: #ffffff !important;
    margin-bottom: var(--unit, 8px) !important;
  }

  .form-icon-inner-sub {
    padding-right: var(--spacing-form-element-decorator, 11px) !important;
  }

  // .form-input {
  //   border-width: 0px !important;
  //     border-style: initial !important;
  //     border-color: initial !important;
  //     border-image: initial !important;
  //   font-size: var(--font-form-element-font-size, 16px) !important;
  //   line-height: var(--font-form-element-line-height, 24px) !important;
  //   letter-spacing: var(--font-form-element-letter-spacing, normal) !important;
  //   font-family: var(
  //     --font-form-element-font-family,
  //     Circular,
  //     -apple-system,
  //     BlinkMacSystemFont,
  //     Roboto,
  //     Helvetica Neue,
  //     sans-serif
  //   ) !important;
  //   text-transform: var(
  //     --font-form-element-text-transform,
  //     undefined
  //   ) !important;
  //   color: #484848 !important;
  //   padding-top: var(--spacing-form-element-vertical, 11px) !important;
  //   padding-bottom: var(--spacing-form-element-vertical, 11px) !important;
  //   font-weight: var(--font-light-font-weight, normal) !important;
  //   background-color: transparent !important;
  //   padding-left: var(--spacing-form-element-horizontal, 11px) !important;
  //   padding-right: var(--spacing-form-element-horizontal, 11px) !important;
  // }
  .form-button {
    font-size: var(--font-button-font-size, 16px) !important;
    line-height: var(--font-button-line-height, 24px) !important;
    letter-spacing: var(--font-button-letter-spacing, normal) !important;

    text-transform: var(--font-button-text-transform, undefined) !important;
    padding-top: var(--spacing-button-vertical, 10px) !important;
    padding-bottom: var(--spacing-button-vertical, 10px) !important;
    font-weight: var(--font-bold-font-weight, 800) !important;
    padding-left: var(--spacing-button-horizontal, 22px) !important;
    padding-right: var(--spacing-button-horizontal, 22px) !important;
    box-shadow: var(--shadow-button-level0-box-shadow, none) !important;
    color: #ffffff !important;
    outline: none;
  }

  .form-button-span {
    font-size: var(--font-button-font-size, 16px) !important;
    line-height: var(--font-button-line-height, 24px) !important;
    letter-spacing: var(--font-button-letter-spacing, normal) !important;

    text-transform: var(--font-button-text-transform, undefined) !important;
    padding-top: var(--font-button-padding-top, undefined) !important;
    padding-bottom: var(--font-button-padding-bottom, undefined) !important;
  }

  .divider-inner-container {
    padding-bottom: var(--spacing-rule-text-vertical, 16px) !important;
    padding-top: var(--spacing-rule-text-vertical, 16px) !important;
    padding-left: var(--spacing-rule-text-horizontal, 16px) !important;
    padding-right: var(--spacing-rule-text-horizontal, 16px) !important;
  }

  .divider-inner-container::before {
    border-bottom-width: var(--border-rule-border-width, 1px) !important;
    border-bottom-color: #e4e4e4 !important;
  }

  .divider-inner-container::after {
    border-bottom-width: var(--border-rule-border-width, 1px) !important;
    border-bottom-color: #e4e4e4 !important;
  }

  .option-item-control-icon {
    height: var(--size-check-box-size, 18px) !important;
    width: var(--size-check-box-size, 18px) !important;
  }

  .options-divider {
    border-bottom-width: var(--border-rule-border-width, 1px) !important;
    border-bottom-color: #ebebeb !important;
  }

  .error {
    font-size: var(--font-form-error-message-font-size, 14px) !important;
    line-height: var(--font-form-error-message-line-height, 18px) !important;
    letter-spacing: var(--font-form-error-message-letter-spacing,
        normal) !important;
    font-family: var(--font-form-error-message-font-family,
        Cereal,
        -apple-system,
        BlinkMacSystemFont,
        Roboto,
        Helvetica Neue,
        sans-serif) !important;
    text-transform: var(--font-form-error-message-text-transform,
        undefined) !important;
    padding-top: var(--font-form-error-message-padding-top,
        undefined) !important;
    padding-bottom: var(--font-form-error-message-padding-bottom,
        undefined) !important;
    font-weight: var(--font-book-font-weight, 600) !important;
    color: #d93900 !important;
  }
}

.a {
  color: rgb(0, 132, 137) !important;
  text-decoration-line: none !important;
  text-align: left !important;
  font: inherit inherit inherit inherit inherit inherit inherit inherit inherit !important;
}

.form-item {
  margin-bottom: 16px;
}

.form-item-content-container {
  display: block !important;
  width: 100% !important;
}

.form-item-label {
  position: absolute !important;
  display: block !important;
  border: 0px !important;
  margin: -1px !important;
  padding: 0px !important;
  height: 1px !important;
  width: 1px !important;
  clip: rect(0, 0, 0, 0) !important;
  overflow: hidden !important;
}

.form-item-control {
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;

  color: rgb(72, 72, 72) !important;
  background-color: rgb(255, 255, 255) !important;
  position: relative !important;
  z-index: 0 !important;
  margin-bottom: 8px !important;
  display: block !important;
  width: 100% !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: rgb(235, 235, 235) !important;
  border-radius: 4px !important;
}

.form-icon-outer-sub {
  float: right !important;
}

.form-icon-inner-sub {
  padding-right: 11px !important;
  visibility: visible;
  height: 46px;
}

.form-icon-outer-container {
  display: table !important;
  position: relative !important;
  height: 100% !important;
}

.form-icon-inner-container {
  display: table-cell !important;
  vertical-align: middle !important;
}

.Email,
.Phone {
  height: 1em;
  width: 1em;
  display: block;
  fill: currentcolor;
}

.form-input::placeholder {
  color: #909090;
}

.form-input-container {
  position: relative !important;
  overflow: hidden !important;
}

.form-input {
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;

  color: rgb(72, 72, 72) !important;
  padding-top: 11px !important;
  padding-bottom: 11px !important;
  font-weight: normal !important;
  background-color: transparent !important;
  padding-left: 11px !important;
  padding-right: 11px !important;
  width: 100% !important;
  border-width: 0px !important;
  border-style: initial !important;
  border-color: initial !important;
  border-image: initial !important;
  margin: 0px !important;
  border: medium;
}

.form-button-container {
  margin-bottom: 16px;
}

.form-button {
  display: inline-block !important;
  position: relative !important;
  text-align: center !important;
  transition-property: background, border-color, color !important;
  transition-duration: 0.2s !important;
  transition-timing-function: ease-out !important;
  cursor: pointer !important;
  width: 100% !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;

  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-weight: 800 !important;
  padding-left: 22px !important;
  padding-right: 22px !important;
  min-width: 71.1935px !important;
  box-shadow: none !important;
  color: rgb(255, 255, 255) !important;
  margin: 0px !important;
  text-decoration: none !important;
  border-radius: 4px !important;
  border-width: 2px !important;
  border-style: solid !important;
  border-color: transparent !important;
}

.form-button-span {
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: normal !important;

  text-transform: undefined !important;
  color: inherit !important;
  padding-top: undefined !important;
  padding-bottom: undefined !important;
}

.divider-outer-container {
  text-align: center !important;
  overflow: hidden !important;
}

.divider-inner-container {
  position: relative !important;
  padding-bottom: 16px !important;
  padding-top: 16px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.divider-inner-container::before {
  content: "" !important;
  position: absolute !important;
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
  border-bottom-color: rgb(228, 228, 228) !important;
  top: 50% !important;
  right: 100% !important;
  width: 5000px !important;
}

.divider-inner-container::after {
  content: "" !important;
  position: absolute !important;
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
  border-bottom-color: rgb(228, 228, 228) !important;
  top: 50% !important;
  left: 100% !important;
  width: 5000px !important;
}

.divider {
  overflow-wrap: break-word !important;

  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 1.28571em !important;
  color: rgb(118, 118, 118) !important;
  margin: 0px !important;
}

.form-item-divider {
  margin-top: 16px;
  margin-bottom: 16px;
}

.options-subtitle-container {
  margin-bottom: 16px;
}

.options-subtitle {
  overflow-wrap: break-word !important;

  font-size: 17px !important;
  font-weight: 600 !important;
  line-height: 1.44444em !important;
  color: rgb(72, 72, 72) !important;
  margin: 0px !important;
}

.option-item {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border-bottom: 0px !important;
}

.option-item-label-outer-container {
  display: table !important;
  width: 100% !important;
  border-spacing: 0px !important;
}

.option-item-label-inner-container {
  display: table-cell !important;
  width: 100% !important;
  vertical-align: middle !important;
}

.option-item-label {
  cursor: pointer !important;
  display: block !important;
  background: transparent !important;
  border-width: 0px !important;
  border-style: initial !important;
  border-color: initial !important;
  border-image: initial !important;
  padding: 0px !important;
}

.option-item-title-outer-container {
  display: table !important;
  width: 100% !important;
  border-spacing: 0px !important;
}

.option-item-title-inner-container {
  display: table-cell !important;
  width: 100% !important;
  vertical-align: top !important;
}

.option-item-title {
  font-weight: normal !important;
  cursor: pointer !important;
  padding-left: 0px !important;
  padding-right: 8px !important;
}

.option-item-title-span {
  font-size: 18px !important;
  line-height: 26px !important;
  letter-spacing: normal !important;

  color: rgb(72, 72, 72) !important;
  display: inline-block !important;
  vertical-align: top !important;
  margin-top: -9px;
}

.option-item-control-outer-container {
  display: table-cell !important;
  vertical-align: top !important;
}

.option-item-control-inner-container {
  position: relative !important;
  top: -11px !important;
}

.option-item-control-span {
  display: inline-block !important;
  cursor: pointer !important;
  padding: 0px !important;
}

.option-item-control-input {
  position: absolute !important;
  width: 0px !important;
  opacity: 0 !important;
}

.option-item-control-icon-inactive {
  height: 32px !important;
  width: 32px !important;
  display: inline-block !important;
  text-align: center !important;
  background: rgb(255, 255, 255) !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: rgb(176, 176, 176) !important;
  border-radius: 8px !important;
  overflow: hidden !important;
}

.option-item-control-icon-active {
  height: 32px !important;
  width: 32px !important;
  display: inline-block !important;
  text-align: center !important;
  color: rgb(255, 255, 255) !important;
  // background: rgb(0, 132, 137) !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-radius: 8px !important;
  overflow: hidden !important;
  // border-color: rgb(0, 132, 137) !important;
}

.option-item-control-icon-inner-span {
  position: relative !important;
  display: inline-block !important;
  left: -1px !important;
  font-size: 32px !important;
  padding-top: 2px !important;
  margin: -8px !important;
}

.Check {
  margin-top: 11px;
  margin-left: 2px;
  height: 20px;
  width: 20px;
  display: block;
  overflow: visible;
  fill: currentcolor;
}

.options-divider-container {
  margin-top: 16px;
  margin-bottom: 16px;
}

.options-divider {
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
  border-bottom-color: rgb(235, 235, 235) !important;
}

.error {
  font-size: 14px !important;
  line-height: 18px !important;
  letter-spacing: normal !important;
  font-family: Cereal, -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif !important;
  font-weight: 600 !important;
  color: rgb(217, 57, 0) !important;
}

@media (max-width: 743px) {
  .sub {
    -webkit-box-direction: normal !important;
    -webkit-box-orient: vertical !important;
    -webkit-flex-direction: column !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .title-outer-container {
    width: 100% !important;
    margin-bottom: 40px !important;
    padding-right: 0px !important;
  }

  .title-inner-container {
    max-width: 100% !important;
  }

  .collection-container {
    -webkit-box-direction: normal !important;
    -webkit-box-orient: vertical !important;
    flex-direction: column !important;
  }

  .item-outer-container {
    max-width: 100% !important;
    flex: 0 0 auto !important;
  }

  .item-inner-container {
    margin-right: 0px !important;
  }
}

@media (max-width: 600px) {
  .AddNotification {
    max-width: 600px;
    display: block;
    position: relative;
  }

  .options-divider-container {
    margin-top: 16px;
    margin-bottom: 78px;
  }
}