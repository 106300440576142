.JourneyCollection {
  position: relative !important;
  z-index: 0 !important;
  background: #f8f8f8 !important;
  height: 100% !important;
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) !important;
  -moz-transition: transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) !important;
  transition: -ms-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    -webkit-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955),
    transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) !important;
  will-change: transform !important;
  width: 100% !important;
  overflow-y: auto !important;
  max-width: 441px;
  margin-bottom: 40px;
  margin-top: calc(var(--header-top-padding)) // margin-top: 30px;
}

.journey-list {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  /*max-width: 441px;*/
  //width: 441px;
}

.visually-hidden {
  top: 0;
  left: -2px;
  width: 1px;
  height: 1px;
  position: absolute;
  overflow: hidden;
}

.outward-return-container {
  background-color: #dddddd;
  color: white;
  margin: 0px !important;
  word-wrap: break-word !important;

  font-size: 16px;
  font-weight: 800;
  line-height: 1.375em !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  padding: 8px 3px 0px 3px;
}

.outward-return-inner-container {
  cursor: pointer;
  padding-top: 3px;
  padding-bottom: 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 50%;
}

.outward-return-date {
  margin: 0px !important;
  word-wrap: break-word !important;

  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.375em !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

@media only screen and (max-width: 600px) {
  .JourneyCollection {
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    margin-top: -30px !important; // remove bottom part for known reason
    background: #ffffff !important;
    border-radius: 0px !important;
    max-width: 1080px !important;
    width: auto;
    box-shadow: none;
    position: relative;
    height: 33.333333vh !important;
    overflow-y: visible !important;
    //min-height: calc(100vh - 430px) !important;
    //bottom: 40px !important;
    z-index: 2 !important;
    //////max-height: inherit !important;
    //height: inherit !important;
    // box-sizing: border-box !important;
    // margin-bottom: 40px !important;
    min-height: 560px;
  }

  .journey-list {
    width: 100%;
    overflow: visible !important;
    //padding-bottom: 40px;
    background-color: #ffffff;
  }

  .journey-list:last-child {
    //Do NOT decrease bottom padding, even if it seems like a gap on emulator, seems nice on the real device
    padding-bottom: 160px !important;
  }

  .HideOnMobile {
    display: none !important
  }
}